// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@keyframes pulse {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0.5;
    }
    100% {
        opacity: 1;
    }
}

@keyframes typingAnimation {
    0% { opacity: 0.3; transform: translateY(0px); }
    50% { opacity: 1; transform: translateY(-2px); }
    100% { opacity: 0.3; transform: translateY(0px); }
}

.typing-dot {
    animation: typingAnimation 1s infinite;
    font-size: 20px;
    line-height: 1;
}

.typing-dot:nth-child(2) {
    animation-delay: 0.2s;
}

.typing-dot:nth-child(3) {
    animation-delay: 0.4s;
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,UAAU;EACV,sBAAsB;AACxB;;AAEA;IACI;QACI,UAAU;IACd;IACA;QACI,YAAY;IAChB;IACA;QACI,UAAU;IACd;AACJ;;AAEA;IACI,KAAK,YAAY,EAAE,0BAA0B,EAAE;IAC/C,MAAM,UAAU,EAAE,2BAA2B,EAAE;IAC/C,OAAO,YAAY,EAAE,0BAA0B,EAAE;AACrD;;AAEA;IACI,sCAAsC;IACtC,eAAe;IACf,cAAc;AAClB;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,qBAAqB;AACzB","sourcesContent":["* {\n  margin: 0;\n  padding: 0;\n  box-sizing: border-box;\n}\n\n@keyframes pulse {\n    0% {\n        opacity: 1;\n    }\n    50% {\n        opacity: 0.5;\n    }\n    100% {\n        opacity: 1;\n    }\n}\n\n@keyframes typingAnimation {\n    0% { opacity: 0.3; transform: translateY(0px); }\n    50% { opacity: 1; transform: translateY(-2px); }\n    100% { opacity: 0.3; transform: translateY(0px); }\n}\n\n.typing-dot {\n    animation: typingAnimation 1s infinite;\n    font-size: 20px;\n    line-height: 1;\n}\n\n.typing-dot:nth-child(2) {\n    animation-delay: 0.2s;\n}\n\n.typing-dot:nth-child(3) {\n    animation-delay: 0.4s;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
