import React from 'react';
import { Box, Typography } from '@mui/material';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

const RecordingIndicator = () => {
    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                backgroundColor: 'rgba(0, 0, 0, 0.8)',
                borderRadius: '20px',
                padding: '6px 12px',
                boxShadow: '0 2px 8px rgba(0,0,0,0.4)',
                backdropFilter: 'blur(4px)',
                border: '1px solid rgba(255, 255, 255, 0.1)'
            }}
        >
            <FiberManualRecordIcon 
                sx={{
                    color: 'error.main',
                    animation: 'pulse 2s infinite',
                    '@keyframes pulse': {
                        '0%': { opacity: 1 },
                        '50%': { opacity: 0.3 },
                        '100%': { opacity: 1 }
                    },
                    mr: 1,
                    fontSize: 16
                }}
            />
            <Typography 
                variant="caption"
                sx={{ 
                    color: 'white',
                    fontWeight: 500,
                    fontSize: '0.75rem'
                }}
            >
                REC
            </Typography>
        </Box>
    );
};

export default RecordingIndicator; 