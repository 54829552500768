import React, { useState, useEffect } from 'react';
import { 
    Box, 
    IconButton, 
    Tooltip, 
    Badge,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    FormControlLabel,
    Checkbox,
    Typography
} from '@mui/material';
import MicIcon from '@mui/icons-material/Mic';
import MicOffIcon from '@mui/icons-material/MicOff';
import VideocamIcon from '@mui/icons-material/Videocam';
import VideocamOffIcon from '@mui/icons-material/VideocamOff';
import CallEndIcon from '@mui/icons-material/CallEnd';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ChatIcon from '@mui/icons-material/Chat';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import PeopleIcon from '@mui/icons-material/People';
import ScreenShareIcon from '@mui/icons-material/ScreenShare';
import StopScreenShareIcon from '@mui/icons-material/StopScreenShare';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import StopCircleIcon from '@mui/icons-material/StopCircle';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { useRoom } from '../../contexts/RoomContext';

const Controls = ({ 
    isMuted,
    isVideoOff,
    onToggleAudio,
    onToggleVideo,
    onLeaveRoom,
    onCopyRoomId,
    onToggleChat,
    isChatOpen,
    disabled,
    onToggleGPTChat,
    isGPTChatOpen,
    onToggleUsers,
    isUsersOpen,
    usersCount = 0,
    webRTCService,
    onToggleTranscript,
    isTranscriptActive,
    isHost,
    stream,
    onRecordingStateChange
}) => {
    const [isScreenSharing, setIsScreenSharing] = useState(false);
    const [screenStream, setScreenStream] = useState(null);
    const { unreadCount } = useRoom();
    const [isMobile, setIsMobile] = useState(false);
    const [isRecordingDialogOpen, setIsRecordingDialogOpen] = useState(false);
    const [notifyParticipants, setNotifyParticipants] = useState(true);
    const { roomIsRecording } = useRoom();

    // Определяем, является ли устройство мобильным
    useEffect(() => {
        const checkMobile = () => {
            const isMobileDevice = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                navigator.userAgent
            );
            setIsMobile(isMobileDevice);
        };

        checkMobile();
        window.addEventListener('resize', checkMobile);
        
        return () => window.removeEventListener('resize', checkMobile);
    }, []);

    const handleScreenShare = async () => {
        try {
            if (!isScreenSharing) {
                const stream = await webRTCService.getDisplayMedia();
                setScreenStream(stream);
                setIsScreenSharing(true);

                // Слушаем событие окончания демонстрации экрана
                stream.getVideoTracks()[0].addEventListener('ended', () => {
                    setIsScreenSharing(false);
                    setScreenStream(null);
                });
            } else {
                // Останавливаем все треки демонстрации экрана
                screenStream.getTracks().forEach(track => track.stop());
                setIsScreenSharing(false);
                setScreenStream(null);
            }
        } catch (error) {
            console.error('Failed to toggle screen sharing:', error);
        }
    };

    const handleRecordingClick = () => {
        if (!isHost) {
            alert('Only room creator can control recording');
            return;
        }
        
        if (roomIsRecording) {
            // Останавливаем запись
            webRTCService.stopRecording()
                .then(() => {
                    onRecordingStateChange(false, false);
                })
                .catch(error => {
                    console.error('Error stopping recording:', error);
                });
        } else {
            // Открываем диалог для настройки записи
            setIsRecordingDialogOpen(true);
        }
    };

    const handleStartRecording = async () => {
        try {
            console.log('Starting recording...');
            
            const displayStream = await navigator.mediaDevices.getDisplayMedia({
                preferCurrentTab: true,
                video: {
                    frameRate: 30,
                    width: { ideal: 1920 },
                    height: { ideal: 1080 }
                },
                audio: true
            });

            // Автоматически останавливаем запись при остановке демонстрации экрана
            displayStream.getVideoTracks()[0].addEventListener('ended', () => {
                console.log('Screen sharing ended, stopping recording');
                if (webRTCService.isRecording()) {
                    webRTCService.stopRecording()
                        .then(() => {
                            onRecordingStateChange(false, false);
                        })
                        .catch(error => {
                            console.error('Error stopping recording:', error);
                        });
                }
            });

            // Начинаем запись через WebRTCService
            const success = await webRTCService.startRecording(displayStream);
            if (success) {
                onRecordingStateChange(true, notifyParticipants);
                setIsRecordingDialogOpen(false);
            }

        } catch (error) {
            console.error('Error starting recording:', error);
            alert('Failed to start recording. Please try again.');
        }
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'center',
                alignItems: 'center',
                gap: { xs: 1, sm: 2 },
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                padding: { xs: 1, sm: 2 },
                borderRadius: 2,
                backdropFilter: 'blur(10px)',
                maxWidth: '100%',
                mx: 'auto',
            }}
        >
            {/* Левая группа - основные контролы */}
            <Box sx={{ 
                display: 'flex', 
                gap: { xs: 1, sm: 2 },
                flexWrap: 'wrap',
                justifyContent: 'center'
            }}>
                <Tooltip title={isMuted ? "Unmute microphone" : "Mute microphone"}>
                    <IconButton
                        onClick={onToggleAudio}
                        color={isMuted ? 'error' : 'primary'}
                        disabled={disabled}
                        sx={controlButtonStyle}
                    >
                        {isMuted ? <MicOffIcon /> : <MicIcon />}
                    </IconButton>
                </Tooltip>

                <Tooltip title={isVideoOff ? "Turn on camera" : "Turn off camera"}>
                    <IconButton
                        onClick={onToggleVideo}
                        color={isVideoOff ? 'error' : 'primary'}
                        disabled={disabled}
                        sx={controlButtonStyle}
                    >
                        {isVideoOff ? <VideocamOffIcon /> : <VideocamIcon />}
                    </IconButton>
                </Tooltip>

                {!isMobile && (
                    <Tooltip title={isScreenSharing ? "Stop sharing screen" : "Share screen"}>
                        <span>
                            <IconButton
                                onClick={handleScreenShare}
                                color={isScreenSharing ? 'primary' : 'default'}
                                disabled={disabled}
                                sx={controlButtonStyle}
                            >
                                {isScreenSharing ? <StopScreenShareIcon /> : <ScreenShareIcon />}
                            </IconButton>
                        </span>
                    </Tooltip>
                )}

                <Tooltip title="Copy room ID to clipboard">
                    <IconButton
                        onClick={onCopyRoomId}
                        color="primary"
                        disabled={disabled}
                        sx={controlButtonStyle}
                    >
                        <ContentCopyIcon />
                    </IconButton>
                </Tooltip>
            </Box>

            {/* Центральная группа - кнопка выхода */}
            <Tooltip title="Leave meeting">
                <IconButton
                    onClick={onLeaveRoom}
                    color="error"
                    disabled={disabled}
                    sx={controlButtonStyle}
                >
                    <CallEndIcon />
                </IconButton>
            </Tooltip>

            {/* Правая группа - дополнительные функции */}
            <Box sx={{ 
                display: 'flex', 
                gap: { xs: 1, sm: 2 },
                flexWrap: 'wrap',
                justifyContent: 'center'
            }}>
                <Tooltip title={isChatOpen ? "Close chat" : "Open chat"}>
                    <IconButton 
                        onClick={onToggleChat}
                        color={isChatOpen ? 'primary' : 'default'}
                        disabled={disabled}
                        sx={controlButtonStyle}
                    >
                        <Badge badgeContent={unreadCount} color="error">
                            <ChatIcon />
                        </Badge>
                    </IconButton>
                </Tooltip>

                <Tooltip title={isGPTChatOpen ? "Close AI Assistant" : "Open AI Assistant"}>
                    <IconButton 
                        onClick={onToggleGPTChat}
                        color={isGPTChatOpen ? 'primary' : 'default'}
                        disabled={disabled}
                        sx={controlButtonStyle}
                    >
                        <AutoAwesomeIcon />
                    </IconButton>
                </Tooltip>

                <Tooltip title={isUsersOpen ? "Close participants panel" : "Show participants"}>
                    <IconButton 
                        onClick={onToggleUsers}
                        color={isUsersOpen ? 'primary' : 'default'}
                        disabled={disabled}
                        sx={controlButtonStyle}
                    >
                        <Box sx={{ position: 'relative' }}>
                            <PeopleIcon />
                            {usersCount > 0 && (
                                <Box sx={userCountBadgeStyle}>
                                    {usersCount}
                                </Box>
                            )}
                        </Box>
                    </IconButton>
                </Tooltip>

                {!isMobile && (
                    <Tooltip title={isTranscriptActive ? "Stop recording transcript" : "Start recording transcript"}>
                        <span>
                            <IconButton
                                onClick={onToggleTranscript}
                                sx={{
                                    ...controlButtonStyle,
                                    backgroundColor: isTranscriptActive ? 'error.main' : 'default',
                                    '&:hover': {
                                        backgroundColor: isTranscriptActive ? 'error.dark' : 'default',
                                    }
                                }}
                            >
                                {isTranscriptActive ? <StopCircleIcon /> : <RecordVoiceOverIcon />}
                            </IconButton>
                        </span>
                    </Tooltip>
                )}

                {!isMobile && isHost && (
                    <Tooltip title={roomIsRecording ? "Stop recording" : "Start recording"}>
                        <span>
                            <IconButton
                                onClick={handleRecordingClick}
                                color={roomIsRecording ? "error" : "default"}
                                sx={{
                                    '&:hover': {
                                        backgroundColor: roomIsRecording ? 'error.dark' : undefined
                                    }
                                }}
                            >
                                <FiberManualRecordIcon />
                            </IconButton>
                        </span>
                    </Tooltip>
                )}
            </Box>

            {/* Диалог настроек записи */}
            <Dialog
                open={isRecordingDialogOpen}
                onClose={() => setIsRecordingDialogOpen(false)}
            >
                <DialogTitle>Recording Settings</DialogTitle>
                <DialogContent>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={notifyParticipants}
                                onChange={(e) => setNotifyParticipants(e.target.checked)}
                            />
                        }
                        label="Show recording indicator to other participants"
                    />
                </DialogContent>
                <DialogContent sx={{ pt: 0 }}>
                    {notifyParticipants ? (
                        <Typography variant="body2" color="text.secondary">
                            Other participants will see a recording indicator during the call
                        </Typography>
                    ) : (
                        <Typography variant="body2" color="text.secondary">
                            Recording will be silent - participants won't see any indicators
                        </Typography>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setIsRecordingDialogOpen(false)}>
                        Cancel
                    </Button>
                    <Button onClick={handleStartRecording} variant="contained" color="primary">
                        Start Recording
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

const controlButtonStyle = {
    width: { xs: '40px', sm: '48px' },
    height: { xs: '40px', sm: '48px' },
    '& .MuiSvgIcon-root': {
        fontSize: { xs: '20px', sm: '24px' }
    }
};

const userCountBadgeStyle = {
    position: 'absolute',
    top: -8,
    right: -8,
    backgroundColor: 'primary.main',
    color: 'primary.contrastText',
    borderRadius: '50%',
    width: 16,
    height: 16,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '0.75rem'
};

export default Controls; 