import React, { createContext, useContext, useState, useCallback, useEffect } from 'react';
import socketService from '../services/socket';

const RoomContext = createContext();

export const RoomProvider = ({ children }) => {
    const [participants, setParticipants] = useState([]);
    const [localUser, setLocalUser] = useState(null);
    const [isRoomInitialized, setIsRoomInitialized] = useState(false);
    const [messages, setMessages] = useState([]);
    const [unreadCount, setUnreadCount] = useState(0);
    const [isChatOpen, setIsChatOpen] = useState(false);
    const [directMessages, setDirectMessages] = useState({});
    const [unreadDirectMessages, setUnreadDirectMessages] = useState({});
    const [isRecording, setIsRecording] = useState(false);
    const [showRecordingIndicator, setShowRecordingIndicator] = useState(true);
    const [roomId, setRoomId] = useState(null);
    const [activeChat, setActiveChat] = useState(null);
    const [roomIsRecording, setRoomIsRecording] = useState(false);

    // Инициализация комнаты
    const initializeRoom = useCallback((id) => {
        console.log('Initializing room:', {
            id,
            socketConnected: socketService.socket?.connected,
            socketId: socketService.socket?.id
        });

        setParticipants([]);
        setLocalUser(null);
        setRoomId(id);
        setIsRoomInitialized(true);

        // Запрашиваем состояние записи при инициализации комнаты
        if (socketService.socket?.connected) {
            console.log('Requesting initial recording state for room:', id);
            socketService.socket.emit('get-recording-state', { roomId: id });
        } else {
            console.warn('Socket not connected during room initialization');
        }
    }, []);

    // Очистка при выходе из комнаты
    const cleanupRoom = useCallback(() => {
        setParticipants([]);
        setLocalUser(null);
        setIsRoomInitialized(false);
        setDirectMessages({});
        setMessages([]);
        setUnreadCount(0);
        setIsChatOpen(false);
        setUnreadDirectMessages({});
        setRoomId(null);
        setIsRecording(false);
        setShowRecordingIndicator(true);
    }, []);

    // Добавление нового участника
    const addParticipant = useCallback((participant) => {
        if (!isRoomInitialized) return;
        setParticipants(prev => {
            if (!prev.find(p => p.id === participant.id)) {
                return [...prev, participant];
            }
            return prev;
        });
    }, [isRoomInitialized]);

    // Удаление участника
    const removeParticipant = useCallback((participantId) => {
        if (!isRoomInitialized) return;
        setParticipants(prev => prev.filter(p => p.id !== participantId));
    }, [isRoomInitialized]);

    // Обновление статуса участника
    const updateParticipantStatus = useCallback((participantId, updates) => {
        if (!isRoomInitialized) return;
        setParticipants(prev => 
            prev.map(p => 
                p.id === participantId 
                    ? { ...p, ...updates }
                    : p
            )
        );
    }, [isRoomInitialized]);

    // Установка локальноо пользователя
    const setLocalParticipant = useCallback((user) => {
        if (!isRoomInitialized) return;
        setLocalUser(user);
        addParticipant({ ...user, isLocal: true });
    }, [isRoomInitialized, addParticipant]);

    // Добавление сообщения в общий чат
    const addMessage = useCallback((message, isFromSocket = false) => {
        // Проверяем, есть ли уже такое сообщение в списке
        setMessages(prev => {
            if (!prev.find(m => m.id === message.id)) {
                return [...prev, {
                    id: message.id || Date.now(),
                    timestamp: message.timestamp || new Date(),
                    ...message
                }];
            }
            return prev;
        });

        // Увеличиваем счетчик только для входящих сообщений через сокет
        if (isFromSocket && !isChatOpen && message.senderId !== localUser?.id) {
            console.log('Increasing unread count for message:', message);
            setUnreadCount(prev => prev + 1);
        }
    }, [isChatOpen, localUser]);

    // Очистка непрочитанных сообщений
    const clearUnreadCount = useCallback(() => {
        setUnreadCount(0);
    }, []);

    // Добавление личного сообщения
    const addDirectMessage = useCallback((message, isFromSocket = false) => {
        const chatId = message.from === localUser?.id ? message.to : message.from;
        
        console.log('RoomContext adding direct message:', {
            message,
            chatId,
            localUserId: localUser?.id,
            isFromSocket,
            currentMessages: directMessages[chatId]
        });
        
        setDirectMessages(prev => {
            const chatMessages = prev[chatId] || [];
            if (!chatMessages.find(m => m.id === message.id)) {
                // Увеличиваем счетчик только если:
                // 1. Сообщение пришло через сокет
                // 2. Сообщение не от текущего пользователя
                if (isFromSocket && message.from !== localUser?.id) {
                    setUnreadDirectMessages(prev => ({
                        ...prev,
                        [chatId]: (prev[chatId] || 0) + 1
                    }));
                }

                return {
                    ...prev,
                    [chatId]: [...chatMessages, message]
                };
            }
            return prev;
        });
    }, [localUser, directMessages]);

    // Поучение сообщений для конкретного чата
    const getDirectMessages = useCallback((userId) => {
        const messages = directMessages[userId] || [];
        console.log('Getting direct messages:', {
            userId,
            messagesCount: messages.length,
            messages
        });
        return messages;
    }, [directMessages]);

    // Очистка непрочитанных сообщений для конкретного чата
    const clearUnreadDirectMessages = useCallback((userId) => {
        setUnreadDirectMessages(prev => ({
            ...prev,
            [userId]: 0
        }));
    }, []);

    // Обновляем метод updateRecordingState
    const updateRecordingState = useCallback((isRecording, showIndicator) => {
        console.log('Updating recording state:', { isRecording, showIndicator });
        setRoomIsRecording(isRecording);
        setShowRecordingIndicator(showIndicator);
    }, []);

    // Обновляем эффект для обработки событий записи
    useEffect(() => {
        const socket = socketService.socket;
        if (!socket) return;

        const handleRecordingStateUpdate = ({ isRecording, showIndicator }) => {
            console.log('Recording state update received:', { isRecording, showIndicator });
            setRoomIsRecording(isRecording);
            setShowRecordingIndicator(showIndicator);
        };

        socket.on('recording-state-update', handleRecordingStateUpdate);
        
        // При входе в комнату запрашиваем текущее состояние
        if (socket.connected) {
            socket.emit('get-recording-state', { roomId });
        }

        // Добавляем обработчик подключения
        socket.on('connect', () => {
            socket.emit('get-recording-state', { roomId });
        });

        return () => {
            if (socket && socket.connected) {
                socket.off('recording-state-update', handleRecordingStateUpdate);
                socket.off('connect');
            }
        };
    }, [roomId]);

    // Добавляем эффект для отслеживания изменений состояния
    useEffect(() => {
        console.log('Recording state changed:', {
            isRecording,
            showRecordingIndicator
        });
    }, [isRecording, showRecordingIndicator]);

    // Добавляем дополнительный эффект для отладки
    useEffect(() => {
        console.log('Room state:', {
            isRecording,
            showRecordingIndicator,
            localUser,
            participants,
            roomId
        });
    }, [isRecording, showRecordingIndicator, localUser, participants, roomId]);

    // В RoomProvider добавляем эффект для обработки чат-сообщений
    useEffect(() => {
        if (!socketService.socket) return;

        const handleChatMessage = (message) => {
            console.log('Chat message received:', message);
            // Добавляем сообщение с флагом isFromSocket = true
            addMessage(message, true);
        };

        socketService.onChatMessage(handleChatMessage);

        return () => {
            socketService.socket?.off('chat-message', handleChatMessage);
        };
    }, [addMessage]);

    // Обновляем эффект ��ля обработки личных сообщений
    useEffect(() => {
        if (!localUser || !socketService.socket) return;

        console.log('Setting up direct message listener for user:', localUser.id);

        const socket = socketService.socket;

        const handleDirectMessage = (message) => {
            console.log('RoomContext received direct message:', {
                message,
                localUserId: localUser.id
            });
            
            // Проверяем, что сообщение относится к текущему пользователю
            if (message.to === localUser.id || message.from === localUser.id) {
                console.log('Message is relevant, adding to context');
                // Добавляем сообщение с флагом isFromSocket
                addDirectMessage(message, true);
            } else {
                console.log('Message is not relevant for current user');
            }
        };

        socket.on('direct-message', handleDirectMessage);

        return () => {
            console.log('Cleaning up direct message listener for user:', localUser.id);
            if (socket && socket.connected) {
                socket.off('direct-message', handleDirectMessage);
            }
        };
    }, [localUser, addDirectMessage]);

    const value = {
        participants,
        localUser,
        isRoomInitialized,
        messages,
        unreadCount,
        isChatOpen,
        directMessages,
        unreadDirectMessages,
        initializeRoom,
        cleanupRoom,
        setLocalParticipant,
        addParticipant,
        removeParticipant,
        updateParticipantStatus,
        addMessage,
        clearUnreadCount,
        addDirectMessage,
        getDirectMessages,
        clearUnreadDirectMessages,
        isRecording,
        showRecordingIndicator,
        updateRecordingState,
        roomId,
        setLocalUser,
        activeChat,
        setActiveChat,
        roomIsRecording,
        setRoomIsRecording
    };

    return (
        <RoomContext.Provider value={value}>
            {children}
        </RoomContext.Provider>
    );
};

export const useRoom = () => {
    const context = useContext(RoomContext);
    if (!context) {
        throw new Error('useRoom must be used within a RoomProvider');
    }
    return context;
};
