import React, { useState, useRef, useEffect } from 'react';
import {
    Drawer,
    Box,
    Typography,
    IconButton,
    List,
    ListItem,
    TextField,
    Avatar,
    Divider
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SendIcon from '@mui/icons-material/Send';
import { useRoom } from '../../contexts/RoomContext';
import socketService from '../../services/socket';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import CircularProgress from '@mui/material/CircularProgress';

const DirectChat = ({ isOpen, onClose, recipient }) => {
    const [newMessage, setNewMessage] = useState('');
    const { 
        localUser, 
        addDirectMessage, 
        getDirectMessages,
        clearUnreadDirectMessages,
        setActiveChat
    } = useRoom();
    const messagesEndRef = useRef(null);
    const [isDragging, setIsDragging] = useState(false);
    const [uploading, setUploading] = useState(false);
    const fileInputRef = useRef(null);
    const [isTyping, setIsTyping] = useState(false);
    const typingTimeoutRef = useRef(null);
    const safetyTimeoutRef = useRef(null);

    const messages = recipient ? getDirectMessages(recipient.id) : [];

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    useEffect(() => {
        if (isOpen && recipient) {
            setActiveChat('direct');
        } else {
            setActiveChat(null);
            setIsTyping(false);
        }
    }, [isOpen, recipient, setActiveChat]);

    useEffect(() => {
        if (!isOpen || !socketService.socket || !recipient) return;

        const handleTypingStart = ({ userId, username, chatType, recipientId }) => {
            if (chatType !== 'direct' || 
                userId !== recipient.id || 
                recipientId !== socketService.socket.id) {
                return;
            }
            setIsTyping(true);
        };

        const handleTypingStop = ({ userId, chatType, recipientId }) => {
            if (chatType !== 'direct' || 
                userId !== recipient.id || 
                recipientId !== socketService.socket.id) {
                return;
            }
            setIsTyping(false);
        };

        socketService.socket.on('typing-start', handleTypingStart);
        socketService.socket.on('typing-stop', handleTypingStop);

        return () => {
            socketService.socket.off('typing-start', handleTypingStart);
            socketService.socket.off('typing-stop', handleTypingStop);
        };
    }, [isOpen, recipient]);

    const clearTimeouts = () => {
        if (typingTimeoutRef.current) {
            clearTimeout(typingTimeoutRef.current);
        }
        if (safetyTimeoutRef.current) {
            clearTimeout(safetyTimeoutRef.current);
        }
    };

    const handleSend = () => {
        if (!newMessage.trim() || !recipient || !localUser) return;

        const message = {
            id: Date.now(),
            text: newMessage.trim(),
            from: localUser.id,
            to: recipient.id,
            timestamp: new Date().toISOString(),
            senderName: localUser.username
        };

        console.log('DirectChat sending message:', {
            message,
            localUserId: localUser.id,
            recipientId: recipient.id
        });
        
        addDirectMessage(message);
        socketService.sendDirectMessage(message);
        
        socketService.stopTyping('direct', recipient.id);
        if (typingTimeoutRef.current) {
            clearTimeout(typingTimeoutRef.current);
        }
        if (safetyTimeoutRef.current) {
            clearTimeout(safetyTimeoutRef.current);
        }
        setIsTyping(false);
        
        setNewMessage('');
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            handleSend();
        }
    };

    const handleFileSelect = async (files) => {
        if (!files || files.length === 0) return;

        setUploading(true);
        try {
            for (const file of files) {
                if (file.size > 10 * 1024 * 1024) {
                    alert('File size should not exceed 10MB');
                    continue;
                }

                const base64 = await readFileAsBase64(file);
                
                const message = {
                    id: Date.now(),
                    type: 'file',
                    fileType: file.type,
                    fileName: file.name,
                    fileSize: file.size,
                    fileData: base64,
                    from: localUser.id,
                    to: recipient.id,
                    timestamp: new Date().toISOString(),
                    senderName: localUser.username
                };

                addDirectMessage(message);
                socketService.sendDirectMessage(message);
            }
        } catch (error) {
            console.error('Error uploading file:', error);
            alert('Failed to upload file');
        } finally {
            setUploading(false);
        }
    };

    const readFileAsBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsDataURL(file);
        });
    };

    const handleDragOver = (e) => {
        e.preventDefault();
        setIsDragging(true);
    };

    const handleDragLeave = (e) => {
        e.preventDefault();
        setIsDragging(false);
    };

    const handleDrop = (e) => {
        e.preventDefault();
        setIsDragging(false);
        const files = e.dataTransfer.files;
        handleFileSelect(files);
    };

    const handleDownload = (message) => {
        const link = document.createElement('a');
        link.href = message.fileData;
        link.download = message.fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const renderMessage = (message) => {
        const isOwnMessage = message.from === localUser?.id;

        if (message.type === 'file') {
            return (
                <Box
                    onClick={() => handleDownload(message)}
                    sx={{
                        maxWidth: '70%',
                        backgroundColor: isOwnMessage ? 'primary.main' : 'action.hover',
                        color: isOwnMessage ? 'white' : 'text.primary',
                        borderRadius: 2,
                        padding: '8px 12px',
                        cursor: 'pointer',
                        '&:hover': {
                            opacity: 0.9
                        }
                    }}
                >
                    <Box sx={{ 
                        display: 'flex', 
                        alignItems: 'center', 
                        gap: 1,
                        minWidth: 0
                    }}>
                        <InsertDriveFileIcon 
                            sx={{ 
                                flexShrink: 0,
                                color: isOwnMessage ? 'white' : 'primary.main'
                            }} 
                        />
                        <Box sx={{ 
                            minWidth: 0,
                            overflow: 'hidden'
                        }}>
                            <Typography 
                                variant="body2" 
                                sx={{ 
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                    color: isOwnMessage ? 'white' : 'inherit',
                                    fontWeight: 500
                                }}
                            >
                                {message.fileName}
                            </Typography>
                            <Typography 
                                variant="caption"
                                sx={{ 
                                    color: isOwnMessage ? 'rgba(255, 255, 255, 0.7)' : '#666666'
                                }}
                            >
                                {(message.fileSize / 1024).toFixed(1)} KB
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            );
        }

        return (
            <Box sx={{
                maxWidth: '70%',
                backgroundColor: isOwnMessage ? 'primary.main' : 'action.hover',
                color: isOwnMessage ? 'white' : 'text.primary',
                borderRadius: 2,
                padding: '8px 12px'
            }}>
                <Typography variant="body2">
                    {message.text}
                </Typography>
            </Box>
        );
    };

    const handleInputChange = (e) => {
        setNewMessage(e.target.value);

        // ��тправляем событие typing-start
        socketService.startTyping('direct', recipient.id);

        // Сбрасываем предыдущий таймер
        if (typingTimeoutRef.current) {
            clearTimeout(typingTimeoutRef.current);
        }

        // Устанавливаем новый таймер для typing-stop
        typingTimeoutRef.current = setTimeout(() => {
            socketService.stopTyping('direct', recipient.id);
        }, 1000);
    };

    if (!recipient) return null;

    return (
        <Drawer
            anchor="right"
            open={isOpen}
            onClose={onClose}
            variant="persistent"
            sx={{
                width: 320,
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    width: 320,
                    boxSizing: 'border-box',
                },
            }}
        >
            <Box
                sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
            >
                {/* Header */}
                <Box sx={{ 
                    p: 2, 
                    display: 'flex', 
                    alignItems: 'center',
                    borderBottom: 1,
                    borderColor: 'divider'
                }}>
                    <Avatar sx={{ mr: 2 }}>
                        {recipient.username[0].toUpperCase()}
                    </Avatar>
                    <Typography variant="subtitle1" sx={{ flex: 1 }}>
                        {recipient.username}
                    </Typography>
                    <IconButton onClick={onClose} size="small">
                        <CloseIcon />
                    </IconButton>
                </Box>

                {/* Messages */}
                <List sx={{ 
                    flex: 1, 
                    overflow: 'auto',
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 1,
                    position: 'relative'
                }}>
                    {isDragging && (
                        <Box
                            sx={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                                backgroundColor: 'rgba(0, 0, 0, 0.1)',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                border: '2px dashed',
                                borderColor: 'primary.main',
                                zIndex: 1,
                                borderRadius: 1
                            }}
                        >
                            <Box sx={{ textAlign: 'center' }}>
                                <FileUploadIcon sx={{ fontSize: 48, color: 'primary.main' }} />
                                <Typography>Drop files here</Typography>
                            </Box>
                        </Box>
                    )}
                    
                    {messages.map((message) => (
                        <ListItem
                            key={message.id}
                            sx={{
                                flexDirection: 'column',
                                alignItems: message.from === localUser?.id ? 'flex-end' : 'flex-start',
                                gap: 0.5,
                                padding: 0
                            }}
                        >
                            {renderMessage(message)}
                            <Typography 
                                variant="caption" 
                                color="text.secondary"
                                sx={{ px: 1 }}
                            >
                                {new Date(message.timestamp).toLocaleTimeString()}
                            </Typography>
                        </ListItem>
                    ))}
                    <div ref={messagesEndRef} />
                </List>

                {/* Индикатор печати */}
                {isTyping && (
                    <Box
                        sx={{
                            px: 2,
                            py: 1,
                            borderTop: '1px solid',
                            borderColor: 'divider',
                            display: 'flex',
                            alignItems: 'center',
                            gap: 1
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 0.5
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'inline-flex',
                                    gap: 0.5
                                }}
                            >
                                <span className="typing-dot">.</span>
                                <span className="typing-dot">.</span>
                                <span className="typing-dot">.</span>
                            </Box>
                            <Typography variant="caption" color="text.secondary">
                                {recipient.username} is typing...
                            </Typography>
                        </Box>
                    </Box>
                )}

                {/* Input */}
                <Box sx={{ p: 2, display: 'flex', gap: 1, borderTop: '1px solid', borderColor: 'divider' }}>
                    <input
                        type="file"
                        ref={fileInputRef}
                        style={{ display: 'none' }}
                        onChange={(e) => handleFileSelect(e.target.files)}
                        multiple
                    />
                    <IconButton
                        onClick={() => fileInputRef.current?.click()}
                        disabled={uploading}
                        size="small"
                    >
                        {uploading ? <CircularProgress size={24} /> : <AttachFileIcon />}
                    </IconButton>
                    <TextField
                        fullWidth
                        size="small"
                        placeholder="Type a message..."
                        value={newMessage}
                        onChange={handleInputChange}
                        onKeyPress={handleKeyPress}
                        multiline
                        maxRows={4}
                    />
                    <IconButton 
                        color="primary" 
                        onClick={handleSend}
                        disabled={!newMessage.trim()}
                    >
                        <SendIcon />
                    </IconButton>
                </Box>
            </Box>
        </Drawer>
    );
};

export default DirectChat; 