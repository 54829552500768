import { getEnvironment } from './environment';

const env = getEnvironment();

export const API_URL = env.apiUrl;

export const config = {
  api: {
    baseUrl: env.apiUrl,
    endpoints: {
      invite: '/api/invite',
    }
  },
  app: {
    name: 'Video Chat',
    version: '1.0.0'
  }
}; 