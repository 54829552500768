import React, { useState } from 'react';
import { Box, IconButton, Slide } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Controls from './Controls';

const ExpandableControls = ({ 
    isMobile,
    controlsProps // Все пропсы для Controls
}) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    if (!isMobile) {
        return <Controls {...controlsProps} />;
    }

    return (
        <>
            {/* Кнопка для показа/скрытия панели */}
            <Box
                sx={{
                    position: 'fixed',
                    bottom: isExpanded ? '80px' : 0,
                    left: '50%',
                    transform: 'translateX(-50%)',
                    zIndex: 1100,
                    transition: 'bottom 0.3s ease-in-out',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    borderRadius: '16px 16px 0 0',
                    padding: '4px 16px'
                }}
            >
                <IconButton
                    onClick={toggleExpand}
                    sx={{ color: 'white' }}
                >
                    {isExpanded ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
                </IconButton>
            </Box>

            {/* Выдвигающаяся панель управления */}
            <Slide direction="up" in={isExpanded}>
                <Box
                    sx={{
                        position: 'fixed',
                        bottom: 0,
                        left: 0,
                        right: 0,
                        backgroundColor: 'rgba(0, 0, 0, 0.8)',
                        backdropFilter: 'blur(10px)',
                        padding: '16px',
                        transition: 'transform 0.3s ease-in-out',
                        zIndex: 1000,
                        borderTop: '1px solid rgba(255, 255, 255, 0.1)',
                    }}
                >
                    <Controls {...controlsProps} />
                </Box>
            </Slide>
        </>
    );
};

export default ExpandableControls; 