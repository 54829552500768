import React, { useState, useRef, useEffect } from 'react';
import { 
    Box, 
    Typography, 
    TextField, 
    IconButton,
    List,
    ListItem,
    Button,
    CircularProgress
} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import CloseIcon from '@mui/icons-material/Close';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { useRoom } from '../../contexts/RoomContext';
import socketService from '../../services/socket';

const Chat = ({ isOpen, onClose, roomId }) => {
    const { messages, addMessage, localUser, setActiveChat } = useRoom();
    const [newMessage, setNewMessage] = useState('');
    const [isDragging, setIsDragging] = useState(false);
    const [uploading, setUploading] = useState(false);
    const messagesEndRef = useRef(null);
    const fileInputRef = useRef(null);
    const [typingUsers, setTypingUsers] = useState(new Set());
    const typingTimeoutRef = useRef(null);
    const safetyTimeoutRef = useRef(null);

    const scrollToBottom = () => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ 
                behavior: "smooth",
                block: "end"
            });
        }
    };

    useEffect(() => {
        if (isOpen) {
            setActiveChat('group');
            scrollToBottom();
        } else {
            setActiveChat(null);
            setTypingUsers(new Set());
        }
    }, [isOpen, setActiveChat]);

    useEffect(() => {
        if (!isOpen || !socketService.socket) return;

        const handleTypingStart = ({ userId, username, chatType }) => {
            if (chatType !== 'group' || userId === socketService.socket.id) {
                return;
            }

            setTypingUsers(prev => {
                if (!prev.has(username)) {
                    if (safetyTimeoutRef.current) {
                        clearTimeout(safetyTimeoutRef.current);
                    }
                    
                    safetyTimeoutRef.current = setTimeout(() => {
                        setTypingUsers(prev => {
                            const newSet = new Set(prev);
                            newSet.delete(username);
                            return newSet;
                        });
                    }, 3000);

                    return new Set([...prev, username]);
                }
                return prev;
            });
        };

        const handleTypingStop = ({ userId, username, chatType }) => {
            if (chatType !== 'group') {
                return;
            }

            setTypingUsers(prev => {
                const newSet = new Set(prev);
                newSet.delete(username);
                return newSet;
            });
        };

        socketService.socket.on('typing-start', handleTypingStart);
        socketService.socket.on('typing-stop', handleTypingStop);

        return () => {
            socketService.socket.off('typing-start', handleTypingStart);
            socketService.socket.off('typing-stop', handleTypingStop);
            clearTimeouts();
        };
    }, [isOpen]);

    const clearTimeouts = () => {
        if (typingTimeoutRef.current) {
            clearTimeout(typingTimeoutRef.current);
        }
        if (safetyTimeoutRef.current) {
            clearTimeout(safetyTimeoutRef.current);
        }
    };

    const handleSend = () => {
        if (!newMessage.trim()) return;

        const messageData = {
            id: Date.now(),
            text: newMessage.trim(),
            senderId: localUser?.id,
            senderName: localUser?.username || 'You',
            type: 'chat',
            timestamp: new Date()
        };

        addMessage(messageData);
        socketService.sendChatMessage(messageData);
        setNewMessage('');
        
        // Отправляем событие остановки печати
        socketService.stopTyping('group');
        if (typingTimeoutRef.current) {
            clearTimeout(typingTimeoutRef.current);
        }
        
        setTimeout(scrollToBottom, 100);
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            handleSend();
        }
    };

    const formatTime = (date) => {
        return new Date(date).toLocaleTimeString([], { 
            hour: '2-digit', 
            minute: '2-digit' 
        });
    };

    const handleFileSelect = async (files) => {
        if (!files || files.length === 0) return;

        setUploading(true);
        try {
            for (const file of files) {
                // Проверяем размер файла (например, максимум 10MB)
                if (file.size > 10 * 1024 * 1024) {
                    alert('File size should not exceed 10MB');
                    continue;
                }

                // Читаем файл как base64
                const base64 = await readFileAsBase64(file);
                
                const messageData = {
                    id: Date.now(),
                    text: file.name,
                    senderId: localUser?.id,
                    senderName: localUser?.username || 'You',
                    type: 'file',
                    fileType: file.type,
                    fileName: file.name,
                    fileSize: file.size,
                    fileData: base64,
                    timestamp: new Date()
                };

                socketService.sendChatMessage(messageData);
                addMessage(messageData);
            }
        } catch (error) {
            console.error('Error uploading file:', error);
            alert('Failed to upload file');
        } finally {
            setUploading(false);
        }
    };

    const readFileAsBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsDataURL(file);
        });
    };

    const handleDragOver = (e) => {
        e.preventDefault();
        setIsDragging(true);
    };

    const handleDragLeave = (e) => {
        e.preventDefault();
        setIsDragging(false);
    };

    const handleDrop = (e) => {
        e.preventDefault();
        setIsDragging(false);
        const files = e.dataTransfer.files;
        handleFileSelect(files);
    };

    const handleDownload = (message) => {
        const link = document.createElement('a');
        link.href = message.fileData;
        link.download = message.fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const renderMessage = (message) => {
        const isOwnMessage = message.senderId === localUser?.id;

        if (message.type === 'file') {
            return (
                <Box
                    onClick={() => handleDownload(message)}
                    sx={{
                        maxWidth: '70%',
                        backgroundColor: isOwnMessage ? 'primary.main' : 'action.hover',
                        color: isOwnMessage ? 'white' : 'text.primary',
                        borderRadius: 2,
                        padding: '8px 12px',
                        cursor: 'pointer',
                        '&:hover': {
                            opacity: 0.9
                        }
                    }}
                >
                    <Box sx={{ 
                        display: 'flex', 
                        alignItems: 'center', 
                        gap: 1,
                        minWidth: 0
                    }}>
                        <InsertDriveFileIcon 
                            sx={{ 
                                flexShrink: 0,
                                color: isOwnMessage ? 'white' : 'primary.main'
                            }} 
                        />
                        <Box sx={{ 
                            minWidth: 0,
                            overflow: 'hidden'
                        }}>
                            <Typography 
                                variant="body2" 
                                sx={{ 
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                    color: isOwnMessage ? 'white' : 'inherit',
                                    fontWeight: 500
                                }}
                            >
                                {message.fileName}
                            </Typography>
                            <Typography 
                                variant="caption"
                                sx={{ 
                                    color: isOwnMessage ? 'rgba(255, 255, 255, 0.7)' : '#666666'
                                }}
                            >
                                {(message.fileSize / 1024).toFixed(1)} KB
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            );
        }

        return (
            <Box sx={{
                maxWidth: '70%',
                backgroundColor: isOwnMessage ? 'primary.main' : 'action.hover',
                color: isOwnMessage ? 'white' : 'text.primary',
                borderRadius: 2,
                padding: '8px 12px'
            }}>
                <Typography variant="body2">
                    {message.text}
                </Typography>
            </Box>
        );
    };

    const handleInputChange = (e) => {
        setNewMessage(e.target.value);

        if (!roomId || !socketService.socket) {
            console.warn('No roomId or socket connection');
            return;
        }

        console.log('Chat: Sending typing event:', {
            socketId: socketService.socket?.id,
            roomId: roomId,
            username: localUser?.username,
            rooms: Array.from(socketService.socket.rooms || [])
        });

        // Отправляем событие typing-start с roomId
        socketService.startTyping('group', null, roomId);

        // Сбрасываем предыдущий таймер
        if (typingTimeoutRef.current) {
            clearTimeout(typingTimeoutRef.current);
        }

        // Устанавливаем новый ��аймер для typing-stop
        typingTimeoutRef.current = setTimeout(() => {
            socketService.stopTyping('group', null, roomId);
        }, 1000);
    };

    return (
        <Box
            sx={{
                position: 'fixed',
                right: 0,
                top: 0,
                bottom: 0,
                width: '300px',
                backgroundColor: 'background.paper',
                transform: isOpen ? 'translateX(0)' : 'translateX(100%)',
                transition: 'transform 0.3s ease-in-out',
                display: 'flex',
                flexDirection: 'column',
                borderLeft: '1px solid',
                borderColor: 'divider',
                zIndex: 1200,
                boxShadow: '-4px 0 8px rgba(0, 0, 0, 0.1)'
            }}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
        >
            {/* Заголовок */}
            <Box sx={{ 
                p: 2, 
                borderBottom: '1px solid',
                borderColor: 'divider',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between'
            }}>
                <Typography variant="h6">Chat</Typography>
                <IconButton onClick={onClose} size="small">
                    <CloseIcon />
                </IconButton>
            </Box>

            {/* Список сообщений */}
            <List sx={{ 
                flex: 1, 
                overflow: 'auto',
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                gap: 1,
                position: 'relative'
            }}>
                {/* Индикатор drag-and-drop */}
                {isDragging && (
                    <Box
                        sx={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            backgroundColor: 'rgba(0, 0, 0, 0.1)',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            border: '2px dashed',
                            borderColor: 'primary.main',
                            zIndex: 1,
                            borderRadius: 1
                        }}
                    >
                        <Box sx={{ textAlign: 'center' }}>
                            <FileUploadIcon sx={{ fontSize: 48, color: 'primary.main' }} />
                            <Typography>Drop files here</Typography>
                        </Box>
                    </Box>
                )}

                {messages.map((message) => (
                    <ListItem
                        key={message.id}
                        sx={{
                            flexDirection: 'column',
                            alignItems: message.senderId === localUser?.id ? 'flex-end' : 'flex-start',
                            gap: 0.5,
                            padding: 0
                        }}
                    >
                        {renderMessage(message)}
                        <Box sx={{ 
                            display: 'flex', 
                            alignItems: 'center',
                            gap: 1,
                            px: 1
                        }}>
                            <Typography variant="caption" color="text.secondary">
                                {message.senderName}
                            </Typography>
                            <Typography variant="caption" color="text.secondary">
                                {formatTime(message.timestamp)}
                            </Typography>
                        </Box>
                    </ListItem>
                ))}
                <div ref={messagesEndRef} />
            </List>

            {/* Индикатор печати */}
            {typingUsers.size > 0 && (
                <Box
                    sx={{
                        px: 2,
                        py: 1,
                        borderTop: '1px solid',
                        borderColor: 'divider',
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 0.5
                        }}
                    >
                        <Box
                            sx={{
                                display: 'inline-flex',
                                gap: 0.5
                            }}
                        >
                            <span className="typing-dot">.</span>
                            <span className="typing-dot">.</span>
                            <span className="typing-dot">.</span>
                        </Box>
                        <Typography variant="caption" color="text.secondary">
                            {Array.from(typingUsers).join(', ')} {typingUsers.size === 1 ? 'is' : 'are'} typing...
                        </Typography>
                    </Box>
                </Box>
            )}

            {/* Поле ввода */}
            <Box sx={{ 
                p: 2,
                borderTop: '1px solid',
                borderColor: 'divider',
                display: 'flex',
                gap: 1,
                alignItems: 'flex-start'
            }}>
                <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                    onChange={(e) => handleFileSelect(e.target.files)}
                    multiple
                />
                <IconButton
                    onClick={() => fileInputRef.current?.click()}
                    disabled={uploading}
                    size="small"
                >
                    {uploading ? <CircularProgress size={24} /> : <AttachFileIcon />}
                </IconButton>
                <TextField
                    fullWidth
                    size="small"
                    placeholder="Type a message..."
                    value={newMessage}
                    onChange={handleInputChange}
                    onKeyPress={handleKeyPress}
                    multiline
                    maxRows={4}
                    sx={{ flex: 1 }}
                />
                <IconButton 
                    color="primary"
                    onClick={handleSend}
                    disabled={!newMessage.trim() || uploading}
                >
                    <SendIcon />
                </IconButton>
            </Box>
        </Box>
    );
};

export default Chat; 