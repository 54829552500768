import { createSafeContext } from '../utils/safeContext';

const configuration = {
    iceServers: [
        { urls: process.env.STUN_SERVER_URLS?.split(',') || [
            'stun:stun.l.google.com:19302',
            'stun:stun1.l.google.com:19302',
            'stun:stun2.l.google.com:19302'
        ]},
        {
            urls: process.env.TURN_SERVER_URLS?.split(',') || ['turn:turn.moneyshop.ua:3478'],
            username: process.env.TURN_SERVER_USERNAME || 'videochat',
            credential: process.env.TURN_SERVER_CREDENTIAL || 'Barrikada5'
        }
    ],
    sdpSemantics: 'unified-plan',
    iceCandidatePoolSize: 10,
    offerOptions: {
        offerToReceiveAudio: true,
        offerToReceiveVideo: true
    }
};

class WebRTCService {
    constructor() {
        this.peerConnections = new Map();
        this.localStream = null;
        this.configuration = configuration;
        this.pendingCandidates = new Map();
        this.mediaOrder = null;
        this.onTrack = null;
        this.onIceCandidate = null;
        this.connectionRetryCount = new Map();
        this.maxRetries = 3;
        this.mediaRecorder = null;
        this.recordedChunks = [];
        this.connectionAttempts = new Map();
        this.maxConnectionAttempts = 3;
        this.connectionRetryDelay = 2000;
    }

    setCallbacks(onTrack, onIceCandidate) {
        this.onTrack = onTrack;
        this.onIceCandidate = onIceCandidate;
    }

    async getLocalStream() {
        return createSafeContext(async () => {
            try {
                if (this.localStream) {
                    return this.localStream;
                }

                const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
                console.log('Device type:', isMobile ? 'mobile' : 'desktop');

                // Настройки для видео с учетом мобильных устройств
                const videoConstraints = {
                    width: { ideal: isMobile ? 640 : 1280 },
                    height: { ideal: isMobile ? 480 : 720 },
                    facingMode: 'user'
                };

                // Улучшаем настройки аудио
                const audioConstraints = {
                    echoCancellation: true,
                    noiseSuppression: true,
                    autoGainControl: true
                };

                try {
                    console.log('Requesting media with constraints:', {
                        video: videoConstraints,
                        audio: audioConstraints
                    });

                    this.localStream = await navigator.mediaDevices.getUserMedia({
                        video: videoConstraints,
                        audio: audioConstraints
                    });

                    // Проверяем полученные треки
                    const videoTrack = this.localStream.getVideoTracks()[0];
                    const audioTrack = this.localStream.getAudioTracks()[0];

                    console.log('Media stream obtained:', {
                        videoTrack: videoTrack ? {
                            enabled: videoTrack.enabled,
                            readyState: videoTrack.readyState
                        } : null,
                        audioTrack: audioTrack ? {
                            enabled: audioTrack.enabled,
                            readyState: audioTrack.readyState
                        } : null
                    });

                    return this.localStream;
                } catch (error) {
                    console.error('Error accessing media devices:', error);
                    throw error;
                }
            } catch (error) {
                console.error('Error in getLocalStream:', error);
                throw error;
            }
        });
    }

    async createPeerConnection(userId) {
        return createSafeContext(async () => {
            try {
                const attempts = this.connectionAttempts.get(userId) || 0;
                if (attempts >= this.maxConnectionAttempts) {
                    console.error(`Max connection attempts reached for user ${userId}`);
                    this.connectionAttempts.delete(userId);
                    throw new Error('Max connection attempts reached');
                }

                this.connectionAttempts.set(userId, attempts + 1);

                if (this.peerConnections.has(userId)) {
                    await this._cleanupConnection(userId);
                }

                console.log(`Creating peer connection for user ${userId} (attempt ${attempts + 1})`);
                const peerConnection = new RTCPeerConnection(this.configuration);

                peerConnection.onconnectionstatechange = () => {
                    console.log(`Connection state changed for ${userId}:`, peerConnection.connectionState);
                    if (peerConnection.connectionState === 'connected') {
                        this.connectionAttempts.delete(userId);
                    } else if (peerConnection.connectionState === 'failed') {
                        this.handleConnectionFailure(userId, peerConnection);
                    }
                };

                peerConnection.oniceconnectionstatechange = () => {
                    console.log(`ICE connection state for ${userId}:`, peerConnection.iceConnectionState);
                    if (peerConnection.iceConnectionState === 'failed') {
                        this.handleICEFailure(userId, peerConnection);
                    }
                };

                peerConnection.onsignalingstatechange = () => {
                    console.log(`Signaling state for ${userId}:`, peerConnection.signalingState);
                };

                peerConnection.ontrack = (event) => {
                    if (!event.streams || !event.streams[0]) {
                        console.warn(`Received track event without streams for user ${userId}`);
                        return;
                    }

                    const stream = event.streams[0];
                    console.log('Received remote track:', {
                        userId,
                        trackKind: event.track.kind,
                        trackEnabled: event.track.enabled,
                        streamId: stream.id
                    });

                    if (event.track.readyState === 'ended') {
                        console.warn(`Track is ended for user ${userId}, attempting to recover`);
                        this.handleTrackEnded(userId, event.track);
                        return;
                    }

                    if (this.onTrack) {
                        this.onTrack(userId, stream);
                    }
                };

                peerConnection.onicecandidate = (event) => {
                    if (event.candidate) {
                        console.log('ICE candidate:', {
                            type: event.candidate.type,
                            protocol: event.candidate.protocol,
                            address: event.candidate.address,
                            port: event.candidate.port,
                            candidateType: event.candidate.candidateType
                        });

                        if (event.candidate.type === 'relay') {
                            console.log('Relay candidate found - using TURN server');
                        }

                        if (this.onIceCandidate) {
                            this.onIceCandidate(userId, event.candidate);
                        }
                    }
                };

                peerConnection.onicegatheringstatechange = () => {
                    console.log('ICE gathering state:', peerConnection.iceGatheringState);
                    if (peerConnection.iceGatheringState === 'complete') {
                        console.log('ICE gathering completed');
                    }
                };

                if (this.localStream) {
                    this.localStream.getTracks().forEach(track => {
                        console.log('Adding local track to peer connection:', {
                            userId,
                            trackKind: track.kind,
                            trackEnabled: track.enabled
                        });
                        peerConnection.addTrack(track, this.localStream);
                    });
                }

                this.peerConnections.set(userId, peerConnection);
                return peerConnection;

            } catch (error) {
                console.error('Error creating peer connection:', error);
                
                if (this.connectionAttempts.get(userId) < this.maxConnectionAttempts) {
                    console.log(`Retrying connection for user ${userId} in ${this.connectionRetryDelay}ms`);
                    await new Promise(resolve => setTimeout(resolve, this.connectionRetryDelay));
                    return this.createPeerConnection(userId);
                }
                
                throw error;
            }
        });
    }

    async createOffer(userId) {
        const peerConnection = this.peerConnections.get(userId);
        if (!peerConnection) {
            throw new Error('No peer connection exists');
        }

        try {
            const offer = await peerConnection.createOffer({
                offerToReceiveAudio: true,
                offerToReceiveVideo: true,
                iceRestart: true
            });

            await peerConnection.setLocalDescription(offer);
            return offer;
        } catch (error) {
            console.error('Error creating offer:', error);
            throw error;
        }
    }

    async handleAnswer(userId, answer) {
        try {
            const peerConnection = this.peerConnections.get(userId);
            if (!peerConnection) {
                throw new Error('No peer connection exists');
            }

            if (peerConnection.signalingState === 'stable') {
                console.warn('PeerConnection already in stable state, ignoring answer');
                return;
            }

            if (peerConnection.signalingState === 'have-local-offer') {
                await peerConnection.setRemoteDescription(new RTCSessionDescription(answer));
            } else {
                console.warn('Invalid signaling state for setting remote description:', peerConnection.signalingState);
            }
        } catch (error) {
            console.error('Error handling answer:', error);
            throw error;
        }
    }

    async handleIceCandidate(userId, candidate) {
        const peerConnection = this.peerConnections.get(userId);
        
        try {
            if (!peerConnection) {
                console.warn('No peer connection for ICE candidate:', userId);
                return;
            }

            if (peerConnection.remoteDescription) {
                await peerConnection.addIceCandidate(new RTCIceCandidate(candidate));
                console.log('ICE candidate added successfully');
            } else {
                console.warn('Skipping ICE candidate - no remote description');
            }
        } catch (error) {
            console.error('Error handling ICE candidate:', error);
            throw error;
        }
    }

    _cleanupConnection(userId) {
        const peerConnection = this.peerConnections.get(userId);
        if (peerConnection) {
            try {
                // Безопасное удаление обработчиков событий
                const safelyRemoveHandler = (obj, event) => {
                    if (obj && typeof obj[event] === 'function') {
                        obj[event] = null;
                    }
                };

                // Безопасно удаляем все обработчики
                if (peerConnection) {
                    safelyRemoveHandler(peerConnection, 'ontrack');
                    safelyRemoveHandler(peerConnection, 'onicecandidate');
                    safelyRemoveHandler(peerConnection, 'oniceconnectionstatechange');
                    safelyRemoveHandler(peerConnection, 'onsignalingstatechange');
                    safelyRemoveHandler(peerConnection, 'onicegatheringstatechange');
                    safelyRemoveHandler(peerConnection, 'onconnectionstatechange');

                    // Безопасно закрываем соединение
                    if (peerConnection.signalingState !== 'closed') {
                        peerConnection.close();
                    }
                }
            } catch (error) {
                console.warn('Error during connection cleanup:', error);
            } finally {
                // Очищаем все связанные данные
                this.peerConnections.delete(userId);
                this.pendingCandidates.delete(userId);
                this.connectionAttempts.delete(userId);
                this.connectionRetryCount.delete(userId);
            }
        }
    }

    async closeConnection(userId) {
        console.log(`Closing connection for user ${userId}`);
        if (this.qualityMonitorInterval) {
            clearInterval(this.qualityMonitorInterval);
            this.qualityMonitorInterval = null;
        }
        await this._cleanupConnection(userId);
    }

    async closeAllConnections() {
        console.log('Closing all connections and cleaning up...');
        
        try {
            // Останавливаем запись, если она активна
            if (this.isRecording()) {
                await this.stopRecording();
            }

            // Останавливаем мониторинг качества
            if (this.qualityMonitorInterval) {
                clearInterval(this.qualityMonitorInterval);
                this.qualityMonitorInterval = null;
            }

            // Безопасно останавливаем все треки локального стрима
            if (this.localStream) {
                const tracks = this.localStream.getTracks();
                tracks.forEach(track => {
                    try {
                        if (track && track.readyState === 'live') {
                            track.stop();
                            console.log(`Stopped local track: ${track.kind}`);
                        }
                    } catch (error) {
                        console.warn(`Error stopping track: ${track.kind}`, error);
                    }
                });
                this.localStream = null;
            }
            
            // Закрываем все peer connections
            const connections = Array.from(this.peerConnections.entries());
            for (const [userId] of connections) {
                await this._cleanupConnection(userId);
            }

            // Очищаем все оставшиеся коллекции
            this.peerConnections.clear();
            this.pendingCandidates.clear();
            this.connectionAttempts.clear();
            this.connectionRetryCount.clear();
            this.mediaOrder = null;
            
            if (this.lastReport) {
                this.lastReport.clear();
            }

            console.log('All connections closed and cleaned up');
        } catch (error) {
            console.error('Error during connections cleanup:', error);
        }
    }

    async restartConnection(userId) {
        console.log(`Attempting to restart connection for user ${userId}`);
        const peerConnection = this.peerConnections.get(userId);
        if (!peerConnection) return;

        try {
            const offer = await peerConnection.createOffer({
                iceRestart: true,
                offerToReceiveAudio: true,
                offerToReceiveVideo: true
            });

            await peerConnection.setLocalDescription(offer);

            return offer;
        } catch (error) {
            console.error('Error restarting connection:', error);
            await this._cleanupConnection(userId);
            return this.createPeerConnection(userId);
        }
    }

    async handleOffer(userId, offer) {
        try {
            let peerConnection = this.peerConnections.get(userId);
            
            if (!peerConnection) {
                peerConnection = await this.createPeerConnection(userId);
            }

            if (peerConnection.signalingState !== 'stable') {
                console.warn('PeerConnection not in stable state, rolling back');
                await Promise.all([
                    peerConnection.setLocalDescription({type: "rollback"}),
                    peerConnection.setRemoteDescription(new RTCSessionDescription(offer))
                ]);
            } else {
                await peerConnection.setRemoteDescription(new RTCSessionDescription(offer));
            }

            const answer = await peerConnection.createAnswer();
            await peerConnection.setLocalDescription(answer);

            return answer;
        } catch (error) {
            console.error('Error handling offer:', error);
            throw error;
        }
    }

    async getDisplayMedia() {
        try {
            const screenStream = await navigator.mediaDevices.getDisplayMedia({
                video: true,
                audio: true
            });
            
            const originalVideoTrack = this.localStream.getVideoTracks()[0];
            
            const screenTrack = screenStream.getVideoTracks()[0];
            
            this.peerConnections.forEach((pc) => {
                const sender = pc.getSenders().find(s => s.track?.kind === 'video');
                if (sender) {
                    sender.replaceTrack(screenTrack);
                }
            });

            screenTrack.addEventListener('ended', () => {
                this.peerConnections.forEach((pc) => {
                    const sender = pc.getSenders().find(s => s.track?.kind === 'video');
                    if (sender) {
                        sender.replaceTrack(originalVideoTrack);
                    }
                });
            });

            return screenStream;
        } catch (error) {
            console.error('Error accessing screen sharing:', error);
            throw error;
        }
    }

    async startQualityMonitoring(peerConnection) {
        const monitorQuality = async () => {
            const stats = await peerConnection.getStats();
            let videoQuality = {
                bitrate: 0,
                packetsLost: 0,
                frameRate: 0,
                resolution: { width: 0, height: 0 }
            };

            stats.forEach(report => {
                if (report.type === 'inbound-rtp' && report.kind === 'video') {
                    const now = report.timestamp;
                    const bytes = report.bytesReceived;
                    const packets = report.packetsLost;
                    const frames = report.framesDecoded;

                    if (this.lastReport && this.lastReport.has(report.id)) {
                        const last = this.lastReport.get(report.id);
                        const deltaTime = now - last.timestamp;
                        const deltaBytes = bytes - last.bytes;
                        const deltaPackets = packets - last.packets;
                        const deltaFrames = frames - last.frames;

                        videoQuality = {
                            bitrate: (deltaBytes * 8) / deltaTime,
                            packetsLost: deltaPackets,
                            frameRate: (deltaFrames * 1000) / deltaTime,
                            resolution: {
                                width: report.frameWidth,
                                height: report.frameHeight
                            }
                        };

                        this.adaptQuality(videoQuality, peerConnection);
                    }

                    if (!this.lastReport) this.lastReport = new Map();
                    this.lastReport.set(report.id, {
                        timestamp: now,
                        bytes,
                        packets,
                        frames
                    });
                }
            });
        };

        this.qualityMonitorInterval = setInterval(monitorQuality, 3000);
    }

    async adaptQuality(quality, peerConnection) {
        try {
            const sender = peerConnection.getSenders()
                .find(s => s.track?.kind === 'video');

            if (!sender) {
                console.warn('No video sender found for quality adaptation');
                return;
            }

            const parameters = sender.getParameters();
            if (!parameters.encodings) {
                parameters.encodings = [{}];
            }

            if (quality.bitrate < 500000) {
                parameters.encodings[0].maxBitrate = 500000;
                parameters.encodings[0].scaleResolutionDownBy = 2;
            } else if (quality.bitrate < 1000000) {
                parameters.encodings[0].maxBitrate = 1000000;
                parameters.encodings[0].scaleResolutionDownBy = 1.5;
            } else {
                parameters.encodings[0].maxBitrate = 2500000;
                parameters.encodings[0].scaleResolutionDownBy = 1;
            }

            await sender.setParameters(parameters);
            console.log('Video quality adapted:', {
                maxBitrate: parameters.encodings[0].maxBitrate,
                scale: parameters.encodings[0].scaleResolutionDownBy
            });
        } catch (error) {
            console.warn('Error adapting video quality:', error);
        }
    }

    async handleConnectionFailure(userId, peerConnection) {
        const retryCount = this.connectionRetryCount.get(userId) || 0;
        
        if (retryCount < this.maxRetries) {
            console.log(`Attempting to reconnect for user ${userId}, attempt ${retryCount + 1}`);
            this.connectionRetryCount.set(userId, retryCount + 1);
            
            try {
                await this.restartConnection(userId);
            } catch (error) {
                console.error(`Failed to restart connection for user ${userId}:`, error);
            }
        } else {
            console.warn(`Max retry attempts reached for user ${userId}`);
            await this._cleanupConnection(userId);
        }
    }

    async handleICEFailure(userId, peerConnection) {
        try {
            console.log(`Attempting ICE restart for user ${userId}`);
            const offer = await peerConnection.createOffer({ iceRestart: true });
            await peerConnection.setLocalDescription(offer);
            
            if (this.onIceCandidate) {
                this.onIceCandidate(userId, { type: 'offer', sdp: offer.sdp });
            }
        } catch (error) {
            console.error(`ICE restart failed for user ${userId}:`, error);
            this.handleConnectionFailure(userId, peerConnection);
        }
    }

    startRecording(displayStream) {
        try {
            if (this.mediaRecorder && this.mediaRecorder.state !== 'inactive') {
                console.warn('Recording is already in progress');
                return false;
            }

            let allConnectionsActive = true;
            this.peerConnections.forEach((pc, userId) => {
                if (pc.connectionState !== 'connected') {
                    console.warn(`Connection with user ${userId} is not active:`, pc.connectionState);
                    allConnectionsActive = false;
                }
            });

            if (!allConnectionsActive) {
                throw new Error('Not all peer connections are active');
            }

            const audioTracks = Array.from(this.peerConnections.values())
                .map(pc => pc.getRemoteStreams()[0])
                .filter(Boolean)
                .flatMap(s => s.getAudioTracks());

            if (this.localStream) {
                audioTracks.push(...this.localStream.getAudioTracks());
            }

            const combinedStream = new MediaStream([
                ...displayStream.getVideoTracks(),
                ...audioTracks
            ]);

            console.log('Starting recording with stream:', {
                videoTracks: combinedStream.getVideoTracks().length,
                audioTracks: combinedStream.getAudioTracks().length,
                connections: Array.from(this.peerConnections.entries()).map(([id, pc]) => ({
                    userId: id,
                    connectionState: pc.connectionState
                }))
            });

            this.recordedChunks = [];
            
            const options = {
                mimeType: 'video/webm;codecs=vp8,opus',
                videoBitsPerSecond: 2500000,
                audioBitsPerSecond: 128000
            };

            this.mediaRecorder = new MediaRecorder(combinedStream, options);

            this.mediaRecorder.ondataavailable = (event) => {
                if (event.data.size > 0) {
                    this.recordedChunks.push(event.data);
                    console.log('Recorded chunk size:', event.data.size);
                }
            };

            this.mediaRecorder.onerror = (error) => {
                console.error('MediaRecorder error:', error);
            };

            this.mediaRecorder.start(1000);
            console.log('MediaRecorder started:', this.mediaRecorder.state);

            return true;
        } catch (error) {
            console.error('Error starting recording:', error);
            throw error;
        }
    }

    stopRecording() {
        return new Promise((resolve, reject) => {
            try {
                if (!this.mediaRecorder || this.mediaRecorder.state === 'inactive') {
                    console.warn('No active recording to stop');
                    resolve();
                    return;
                }

                console.log('Stopping recording...');
                
                this.mediaRecorder.onstop = () => {
                    console.log('Recording stopped, saving file...');
                    this.saveRecording()
                        .then(resolve)
                        .catch(reject);
                };

                this.mediaRecorder.stop();

            } catch (error) {
                console.error('Error stopping recording:', error);
                reject(error);
            }
        });
    }

    async saveRecording() {
        try {
            if (!this.recordedChunks.length) {
                console.warn('No recorded data to save');
                return;
            }

            const blob = new Blob(this.recordedChunks, {
                type: 'video/webm'
            });

            const now = new Date();
            const fileName = `recording-${now.toISOString().slice(0,19).replace(/[:]/g, '-')}.webm`;

            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            document.body.appendChild(a);
            a.style.display = 'none';
            a.href = url;
            a.download = fileName;
            a.click();

            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
            this.recordedChunks = [];
            this.mediaRecorder = null;

            console.log('Recording saved:', fileName);
        } catch (error) {
            console.error('Error saving recording:', error);
            throw error;
        }
    }

    isRecording() {
        return this.mediaRecorder && this.mediaRecorder.state !== 'inactive';
    }

    async handleTrackEnded(userId, track) {
        try {
            const peerConnection = this.peerConnections.get(userId);
            if (!peerConnection || peerConnection.signalingState === 'closed') {
                console.warn(`Cannot handle ended track - connection already closed for user ${userId}`);
                return;
            }

            console.log(`Handling ended track for user ${userId}`, {
                trackKind: track.kind,
                trackEnabled: track.enabled,
                trackReadyState: track.readyState
            });

            await this.restartConnection(userId);
        } catch (error) {
            console.error(`Error handling ended track for user ${userId}:`, error);
        }
    }
}

const webRTCService = new WebRTCService();
export default webRTCService; 