import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { Box, Snackbar, Alert, Typography, Button } from '@mui/material';
import VideoGrid from './VideoGrid';
import Controls from './Controls';
import socketService from '../../services/socket';
import webRTCService from '../../services/webRTC';

import Chat from '../Chat/Chat';
import GPTChat from '../GPTChat/GPTChat';
import UsersPanel from '../UsersPanel/UsersPanel';
import { useRoom } from '../../contexts/RoomContext';
import TranscriptPanel from '../TranscriptPanel/TranscriptPanel';
import ExpandableControls from './ExpandableControls';

const Room = () => {
    const { roomId } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const [streams, setStreams] = useState([]);
    const [isMuted, setIsMuted] = useState(false);
    const [isVideoOff, setIsVideoOff] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('error');
    const [isRetrying] = useState(false);
    const [mediaError, setMediaError] = useState(null);
    const [isChatOpen, setIsChatOpen] = useState(false);
    const [isGPTChatOpen, setIsGPTChatOpen] = useState(false);
    const [isUsersOpen, setIsUsersOpen] = useState(false);
    const [isTranscriptOpen, setIsTranscriptOpen] = useState(false);
    const [isTranscriptActive, setIsTranscriptActive] = useState(false);
    const [transcript, setTranscript] = useState([]);
    const recognitionRefs = useRef(new Map());
    const [isMobile] = useState(() => /iPhone|iPad|iPod|Android/i.test(navigator.userAgent));
    const [isRecording, setIsRecording] = useState(false);
    const [showRecordingIndicator, setShowRecordingIndicator] = useState(false);
    const [isHost, setIsHost] = useState(() => {
        const urlParams = new URLSearchParams(window.location.search);
        return urlParams.get('creator') === 'true';
    });

    const { 
        participants,
        setLocalParticipant,
        addParticipant,
        removeParticipant,
        updateParticipantStatus,
        initializeRoom,
        cleanupRoom,
        addMessage,
        isRecording: roomIsRecording,
        showRecordingIndicator: roomShowRecordingIndicator,
        updateRecordingState,
        localUser,
        clearUnreadCount,
    } = useRoom();

    const generateRandomUsername = () => {
        return `User${Math.random().toString(36).substring(2, 8)}`;
    };

    const [username] = useState(() => {
        return location.state?.username || generateRandomUsername();
    });

    const handleTrack = useCallback((userId, stream, username) => {
        console.log('Handling track for user:', userId, {
            audioTracks: stream.getAudioTracks().length,
            videoTracks: stream.getVideoTracks().length,
            username,
            tracks: stream.getTracks().map(track => ({
                kind: track.kind,
                enabled: track.enabled,
                readyState: track.readyState
            }))
        });

        setStreams(prevStreams => {
            const existingStreamIndex = prevStreams.findIndex(s => s.id === userId);
            
            const newStream = {
                id: userId,
                stream: stream,
                username: username || `User ${userId.slice(0, 4)}`,
                isLocal: userId === socketService.socket.id
            };

            // Добавляем дополнительную проверку состояния видеотрека
            const videoTrack = stream.getVideoTracks()[0];
            if (videoTrack) {
                console.log('Remote video track state:', {
                    enabled: videoTrack.enabled,
                    readyState: videoTrack.readyState,
                    muted: videoTrack.muted,
                    id: videoTrack.id
                });
            } else {
                console.warn('No video track found in remote stream');
            }

            if (existingStreamIndex !== -1) {
                const updatedStreams = [...prevStreams];
                updatedStreams[existingStreamIndex] = newStream;
                return updatedStreams;
            }

            return [...prevStreams, newStream];
        });
    }, []);

    const cleanup = useCallback(() => {
        console.log('Cleaning up resources...');
        
        // Останавливаем все треки лоального стрима
        if (webRTCService.localStream) {
            webRTCService.localStream.getTracks().forEach(track => {
                track.stop();
                console.log(`Stopped track: ${track.kind}`);
            });
            webRTCService.localStream = null;
        }

        // Закрываем все peer connections
        webRTCService.closeAllConnections();
        
        // Отключаем сокет
        socketService.disconnect();
        
        // Очщаем состояние стримов
        setStreams([]);
    }, []);

    const handleLeaveRoom = useCallback(() => {
        cleanup();
        navigate('/');
    }, [cleanup, navigate]);

    const determineIsHost = useCallback(() => {
        // Проверяем URL на наличие параметра join
        const urlParams = new URLSearchParams(window.location.search);
        return !urlParams.get('join');
    }, []);

    useEffect(() => {
        const isCreator = determineIsHost();
        
        if (isCreator) {
            setIsHost(true);
            // Сохраняем информацию о хосте в localStorage
            localStorage.setItem(`room_${roomId}_host`, socketService.socket?.id);
        }
    }, [roomId, determineIsHost]);

    useEffect(() => {
        let mounted = true;

        const handleRoomUsers = async (participants) => {
            if (!mounted) return;
            console.log('Room users:', participants);
            
            // Добавляем новых пользователей
            participants.forEach(participant => {
                if (participant.id !== socketService.socket.id) {
                    addParticipant({
                        id: participant.id,
                        username: participant.username || `User ${participant.id.slice(0, 4)}`,
                        isAudioEnabled: participant.isAudioEnabled ?? true,
                        isVideoEnabled: participant.isVideoEnabled ?? true,
                        isScreenSharing: participant.isScreenSharing ?? false
                    });
                }
            });
        };

        const initializeWebRTC = async () => {
            try {
                if (!mounted) return;
                
                initializeRoom();
                socketService.connect();
                
                // Ждем подключения сокета
                await new Promise((resolve) => {
                    if (socketService.socket.connected) {
                        resolve();
                    } else {
                        socketService.socket.once('connect', resolve);
                    }
                });

                // Получаем локальный стрим
                const localStream = await webRTCService.getLocalStream();
                if (!mounted) return;

                const socketId = socketService.socket.id;
                if (!socketId) {
                    throw new Error('Socket connection failed');
                }

                // Обновляем инициализацию локального стрима
                const localStreamData = {
                    id: socketId,
                    stream: localStream,
                    username: username,
                    isLocal: true
                };

                // Устанавливаем локальный стрим
                setStreams([localStreamData]);

                // Устанавливаем локального пользователя в контекст
                setLocalParticipant({
                    id: socketId,
                    username: username,
                    stream: localStream,
                    isAudioEnabled: true,
                    isVideoEnabled: true,
                    isScreenSharing: false
                });

                // Присоединяемся к комнате
                socketService.joinRoom(roomId, {
                    username: username,
                    isAudioEnabled: true,
                    isVideoEnabled: true,
                    isScreenSharing: false
                });

                // Устанавливаем колбэки WebRTC
                webRTCService.setCallbacks(
                    // onTrack callback
                    (userId, stream) => {
                        console.log('Track received from:', userId);
                        if (mounted && userId !== socketService.socket.id) {
                            const participant = participants.find(p => p.id === userId);
                            handleTrack(userId, stream, participant?.username);
                        }
                    },
                    // onIceCandidate callback
                    (userId, candidate) => {
                        if (mounted) {
                            console.log('Sending ICE candidate to:', userId);
                            socketService.sendSignal(userId, { 
                                type: 'candidate', 
                                candidate 
                            });
                        }
                    }
                );

                // Инициализуе обработчики событий комнаты
                initializeRoomConnection(localStream);

                // Устанавливаем обработчики
                socketService.onRoomUsers(handleRoomUsers);

                // Добавляем обботчик подтверждения хоста
                socketService.socket.on('host-confirmed', () => {
                    setIsHost(true);
                    console.log('Host status confirmed');
                });

                // Проверяем, является ли текущий пользователь создателем комнаты
                const storedHostId = localStorage.getItem(`room_${roomId}_host`);
                const isCreator = determineIsHost();
                
                if (isCreator && socketService.socket) {
                    socketService.socket.emit('set-host', { 
                        roomId,
                        hostId: socketService.socket.id 
                    });
                } else if (storedHostId === socketService.socket?.id) {
                    setIsHost(true);
                }

                socketService.socket.on('user-disconnected', (userId) => {
                    console.log('User disconnected:', userId);
                    webRTCService.closeConnection(userId);
                    removeParticipant(userId);
                });

                socketService.socket.on('connect_error', (error) => {
                    console.error('Socket connection error:', error);
                    // Можно добавить обработку ошибки, например, показать уведомление
                });

                socketService.socket.on('reconnect', (attemptNumber) => {
                    console.log('Socket reconnected after', attemptNumber, 'attempts');
                    // Можно добавить переподключение к комнате
                });
            } catch (error) {
                console.error('Failed to initialize WebRTC:', error);
                if (mounted) {
                    setMediaError(error.message);
                }
            }
        };

        const initializeRoomConnection = (localStream) => {
            if (!localStream) {
                console.error('No local stream available');
                return;
            }

            console.log('Initializing room connection with local stream:', {
                audioTracks: localStream.getAudioTracks().length,
                videoTracks: localStream.getVideoTracks().length,
                tracks: localStream.getTracks().map(t => ({
                    kind: t.kind,
                    enabled: t.enabled,
                    readyState: t.readyState
                }))
            });

            // Обработка списка пользователей в комнате
            socketService.onRoomUsers((participants) => {
                console.log('Room users updated:', participants);
                
                participants.forEach(participant => {
                    if (participant.id !== socketService.socket.id) {
                        addParticipant({
                            id: participant.id,
                            username: participant.username || `User ${participant.id.slice(0, 4)}`,
                            isAudioEnabled: participant.isAudioEnabled ?? true,
                            isVideoEnabled: participant.isVideoEnabled ?? true,
                            isScreenSharing: participant.isScreenSharing ?? false
                        });
                    }
                });
            });

            // Обработка сигналов WebRTC
            socketService.onSignal(async ({ userId, signal }) => {
                console.log('Received signal:', { from: userId, type: signal.type });
                try {
                    switch (signal.type) {
                        case 'offer':
                            console.log('Processing offer from:', userId);
                            let peerConnection = webRTCService.peerConnections.get(userId);
                            if (!peerConnection) {
                                peerConnection = await webRTCService.createPeerConnection(userId);
                            }
                            const answer = await webRTCService.handleOffer(userId, signal.offer);
                            if (answer) {
                                console.log('Sending answer to:', userId);
                                socketService.sendSignal(userId, { 
                                    type: 'answer', 
                                    answer,
                                    username: username
                                });
                            }
                            break;

                        case 'answer':
                            console.log('Processing answer from:', userId);
                            await webRTCService.handleAnswer(userId, signal.answer);
                            break;

                        case 'candidate':
                            console.log('Processing ICE candidate from:', userId);
                            if (signal.candidate) {
                                await webRTCService.handleIceCandidate(userId, signal.candidate);
                            }
                            break;

                        default:
                            console.warn('Unknown signal type:', signal.type);
                    }
                } catch (error) {
                    console.error('Error handling signal:', error);
                    try {
                        console.log('Attempting to restart connection with:', userId);
                        const offer = await webRTCService.restartConnection(userId);
                        if (offer) {
                            socketService.sendSignal(userId, { 
                                type: 'offer', 
                                offer,
                                username: username
                            });
                        }
                    } catch (retryError) {
                        console.error('Failed to restart connection:', retryError);
                    }
                }
            });

            // Обработка нового пользователя
            socketService.onUserJoined(async (participant) => {
                console.log('New user joined:', participant);
                if (participant.id !== socketService.socket.id) {
                    try {
                        // Создаем peer connection для нового пользователя
                        const peerConnection = await webRTCService.createPeerConnection(participant.id);
                        
                        // Создаем и отправляем offer
                        const offer = await webRTCService.createOffer(participant.id);
                        if (offer) {
                            console.log('Sending offer to new user:', participant.id);
                            socketService.sendSignal(participant.id, { 
                                type: 'offer', 
                                offer,
                                username: username
                            });
                        }

                        // Добавляем участника
                        addParticipant({
                            id: participant.id,
                            username: participant.username || `User ${participant.id.slice(0, 4)}`,
                            isAudioEnabled: participant.isAudioEnabled ?? true,
                            isVideoEnabled: participant.isVideoEnabled ?? true,
                            isScreenSharing: participant.isScreenSharing ?? false
                        });

                        // Добавляем системное сообщение
                        addMessage({
                            id: Date.now(),
                            text: `${participant.username} joined the room`,
                            type: 'system',
                            timestamp: new Date()
                        });
                    } catch (error) {
                        console.error('Error connecting to new user:', error);
                    }
                }
            });

            // Обработка выхода пользователя
            socketService.onUserLeft((userId) => {
                console.log('User left:', userId);
                removeParticipant(userId);
                setStreams(prevStreams => prevStreams.filter(s => s.id !== userId));
                webRTCService.closeConnection(userId);
            });

            // Обработка изменения медиа-статуса
            socketService.socket.on('mediaStatus', ({ userId, status }) => {
                console.log('Media status update:', userId, status);
                updateParticipantStatus(userId, status);
            });

            // Добавим периодическую проверку состояния соединений
            const checkConnectionsInterval = setInterval(() => {
                const connections = webRTCService.peerConnections;
                connections.forEach((connection, userId) => {
                    console.log(`Connection state for ${userId}:`, {
                        iceConnectionState: connection.iceConnectionState,
                        signalingState: connection.signalingState,
                        connectionState: connection.connectionState
                    });
                });
            }, 5000);

            // Оработка чат-сообщений
            socketService.socket.on('chat-message', (messageData) => {
                if (messageData.senderId !== localUser?.id) {
                    console.log('Received chat message:', messageData);
                    addMessage(messageData);
                }
            });

            return () => {
                clearInterval(checkConnectionsInterval);
            };
        };

        // Добавляем обрабочк beforeunload
        window.addEventListener('beforeunload', cleanup);
        
        // Инициализируем медиа
        initializeWebRTC().catch(error => {
            console.error('WebRTC initialization failed:', error);
            setMediaError(error.message);
        });

        // Очистка при рамонтировани компонента
        return () => {
            console.log('Component unmounting, cleaning up...');
            cleanup();
            window.removeEventListener('beforeunload', cleanup);
            mounted = false;
            cleanupRoom(); // Очищаем состояние комнаты при размонтироваии
        };
    }, [
        roomId,
        username,
        handleTrack,
        cleanup,
        initializeRoom,
        cleanupRoom,
        setLocalParticipant,
        addParticipant
    ]);

    const handleToggleAudio = useCallback(() => {
        if (webRTCService.localStream) {
            const audioTrack = webRTCService.localStream.getAudioTracks()[0];
            if (audioTrack) {
                audioTrack.enabled = !audioTrack.enabled;
                setIsMuted(!audioTrack.enabled);
                
                const status = {
                    isAudioEnabled: audioTrack.enabled
                };
                
                // Обновяем локальный статус
                updateParticipantStatus(socketService.socket.id, status);
                
                // Оповещаем других участников
                socketService.socket.emit('mediaStatus', {
                    userId: socketService.socket.id,
                    status
                });
            }
        }
    }, [updateParticipantStatus]);

    const handleToggleVideo = useCallback(() => {
        if (webRTCService.localStream) {
            const videoTrack = webRTCService.localStream.getVideoTracks()[0];
            if (videoTrack) {
                videoTrack.enabled = !videoTrack.enabled;
                setIsVideoOff(!videoTrack.enabled);
                
                const status = {
                    isVideoEnabled: videoTrack.enabled
                };
                
                // Обновляем локальный статус
                updateParticipantStatus(socketService.socket.id, status);
                
                // Оповещаем других участников
                socketService.socket.emit('mediaStatus', {
                    userId: socketService.socket.id,
                    status
                });
            }
        }
    }, [updateParticipantStatus]);

    const handleCopyRoomId = useCallback(() => {
        navigator.clipboard.writeText(roomId);
        setSnackbarSeverity('success');
        setSnackbarMessage('Room ID copied to clipboard');
    }, [roomId]);

    const handleToggleChat = useCallback(() => {
        console.log('Toggling chat, current state:', isChatOpen);
        if (!isChatOpen) {
            setIsGPTChatOpen(false);
            setIsUsersOpen(false);
        }
        setIsChatOpen(prev => !prev);
        // Очищаем счетчик только когда открываем чат
        if (!isChatOpen) {
            clearUnreadCount();
        }
    }, [isChatOpen, clearUnreadCount]);

    const handleToggleGPTChat = useCallback(() => {
        setIsGPTChatOpen(prev => !prev);
        // Закрываем обычный чат при открытии GPT чта
        if (!isGPTChatOpen) {
            setIsChatOpen(false);
        }
    }, [isGPTChatOpen]);

    const handleToggleUsers = useCallback(() => {
        setIsUsersOpen(prev => !prev);
        if (!isUsersOpen) {
            setIsChatOpen(false);
            setIsGPTChatOpen(false);
        }
    }, [isUsersOpen]);

    const handleToggleTranscript = useCallback(() => {
        if (!isTranscriptActive) {
            setIsTranscriptActive(true);
            setIsTranscriptOpen(true);

            // Получаем все активные аудиопотоки
            const activeStreams = streams.filter(stream => 
                stream.stream.getAudioTracks().length > 0 && 
                stream.stream.getAudioTracks()[0].enabled
            );

            console.log('Starting transcript for streams:', activeStreams.map(s => ({
                id: s.id,
                username: s.username,
                hasAudio: s.stream.getAudioTracks().length > 0
            })));

            activeStreams.forEach(stream => {
                if (recognitionRefs.current.has(stream.id)) return;

                try {
                    // Создаем контекст для обрботки аудио
                    const audioContext = new AudioContext();
                    const source = audioContext.createMediaStreamSource(stream.stream);
                    
                    // Создаем ильтры ля шумоподавления
                    const lowpass = audioContext.createBiquadFilter();
                    lowpass.type = 'lowpass';
                    lowpass.frequency.value = 8000;
                    lowpass.Q.value = 1;

                    const highpass = audioContext.createBiquadFilter();
                    highpass.type = 'highpass';
                    highpass.frequency.value = 100;
                    highpass.Q.value = 1;

                    // Создаем узел для усиления сигнала
                    const gainNode = audioContext.createGain();
                    gainNode.gain.value = 1.5; // Усиливаем сигнал

                    // Подкючаем фильтры
                    source.connect(highpass);
                    highpass.connect(lowpass);
                    lowpass.connect(gainNode);
                    gainNode.connect(audioContext.destination);

                    // Настраиваем распознавание речи
                    const recognition = new (window.SpeechRecognition || window.webkitSpeechRecognition)();
                    recognition.continuous = true;
                    recognition.interimResults = true;
                    recognition.lang = 'ru-RU';

                    // Увеличиваем чувствительность
                    recognition.maxAlternatives = 1;

                    let currentSpeech = '';
                    let restartTimeout;

                    recognition.onresult = (event) => {
                        const result = event.results[event.results.length - 1];
                        const text = result[0].transcript;
                        
                        if (result[0].confidence > 0.7) {
                            currentSpeech = text;

                            if (result.isFinal) {
                                // Находим актуальную инфорцю б участнике
                                const participant = participants.find(p => p.id === stream.id);
                                const username = participant?.username || stream.username || 'Unknown User';
                                
                                if (currentSpeech.trim()) {
                                    console.log('Adding transcript entry:', {
                                        username,
                                        text: currentSpeech.trim()
                                    });
                                    
                                    setTranscript(prev => [...prev, {
                                        username,
                                        text: currentSpeech.trim(),
                                        timestamp: new Date()
                                    }]);
                                }
                                currentSpeech = '';
                            }
                        }
                    };

                    recognition.onerror = (event) => {
                        console.error('Speech recognition error:', event.error);
                        // Перезапскаем распознавание при ошибке
                        if (event.error === 'no-speech' || event.error === 'network') {
                            recognition.stop();
                            restartTimeout = setTimeout(() => {
                                if (isTranscriptActive) {
                                    recognition.start();
                                }
                            }, 1000);
                        }
                    };

                    recognition.onend = () => {
                        // Автоматически перезапускаем распознавание, если оно все ще активно
                        if (isTranscriptActive) {
                            recognition.start();
                        }
                    };

                    recognition.start();
                    recognitionRefs.current.set(stream.id, {
                        recognition,
                        audioContext,
                        source,
                        filters: { lowpass, highpass, gainNode },
                        restartTimeout
                    });
                } catch (error) {
                    console.error('Error setting up speech recognition for user:', stream.username, error);
                }
            });
        } else {
            setIsTranscriptActive(false);
            
            // Осанавливаем все распознаватели
            recognitionRefs.current.forEach(({ 
                recognition, 
                audioContext, 
                source, 
                filters, 
                restartTimeout 
            }) => {
                recognition.stop();
                clearTimeout(restartTimeout);
                source.disconnect();
                filters.lowpass.disconnect();
                filters.highpass.disconnect();
                filters.gainNode.disconnect();
                audioContext.close();
            });
            recognitionRefs.current.clear();
        }
    }, [isTranscriptActive, streams, participants]);

    // Обновляем cleanup эффект
    useEffect(() => {
        return () => {
            recognitionRefs.current.forEach(({ 
                recognition, 
                audioContext, 
                source, 
                filters, 
                restartTimeout 
            }) => {
                recognition.stop();
                clearTimeout(restartTimeout);
                source.disconnect();
                filters.lowpass.disconnect();
                filters.highpass.disconnect();
                filters.gainNode.disconnect();
                audioContext.close();
            });
            recognitionRefs.current.clear();
        };
    }, []);

    const streamsWithUsernames = streams.map(stream => {
        const participant = participants.find(p => p.id === stream.id);
        console.log('Processing stream:', {
            streamId: stream.id,
            socketId: socketService.socket?.id,
            isStreamLocal: stream.isLocal,
            participant: participant?.id,
            willBeLocal: stream.id === socketService.socket?.id || stream.isLocal === true
        });

        return {
            ...stream,
            username: participant?.username || stream.username || 'Unknown User',
            // Явно устанавливаем isLocal в false для не-локальных стримов
            isLocal: stream.id === socketService.socket?.id || stream.isLocal === true || false
        };
    });

    // Добавим лог после обработки всех стримов
    console.log('Processed streams:', streamsWithUsernames.map(s => ({
        id: s.id,
        username: s.username,
        isLocal: s.isLocal
    })));

    // бновляем обработчик записи
    const handleRecordingStateChange = (recording, notify) => {
        if (!isHost) {
            console.warn('Only room creator can record the meeting');
            return;
        }

        console.log('Recording state change initiated:', { 
            recording, 
            notify, 
            isHost, 
            roomId,
            socketConnected: socketService.socket?.connected,
            peerConnectionsCount: webRTCService.peerConnections.size
        });
        
        const socket = socketService.socket;
        if (!socket?.connected) {
            console.error('Socket not connected, cannot change recording state');
            return;
        }

        try {
            socket.emit('recording-state-change', {
                isRecording: recording,
                showIndicator: notify,
                roomId
            });

            // Добавляем обработчик подтверждения
            socket.once('recording-state-confirmed', () => {
                console.log('Recording state change confirmed by server');
                updateRecordingState(recording, notify);
            });

            // Добавляем таймаут для подтверждения
            setTimeout(() => {
                socket.off('recording-state-confirmed');
                console.warn('Recording state change confirmation timeout');
            }, 5000);

        } catch (error) {
            console.error('Error sending recording state:', error);
        }
    };

    // Обновляем обработчик событий записи
    useEffect(() => {
        const socket = socketService.socket;
        if (!socket) return;

        const handleRecordingStateUpdate = ({ isRecording, showIndicator }) => {
            console.log('Recording state update received:', { isRecording, showIndicator });
            updateRecordingState(isRecording, showIndicator);
        };

        socket.on('recording-state-update', handleRecordingStateUpdate);
        
        // При входе в комнату запрашиваем текущее состояние
        socket.emit('get-recording-state', { roomId });

        return () => {
            if (socket && socket.connected) {
                try {
                    socket.off('recording-state-update', handleRecordingStateUpdate);
                } catch (error) {
                    console.warn('Error removing socket listener:', error);
                }
            }
        };
    }, [updateRecordingState, roomId]);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const isCreator = urlParams.get('creator') === 'true';
        console.log('Room initialization:', { isCreator, isHost });
        setIsHost(isCreator);
    }, []);

    return (
        <Box sx={{ 
            height: isMobile ? '100dvh' : '100vh',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',
            backgroundColor: 'black'
        }}>
            <Box 
                sx={{ 
                    height: '100%',
                    display: 'flex', 
                    flexDirection: 'column',
                    backgroundColor: 'background.default',
                    transition: 'width 0.3s ease-in-out',
                    width: (isChatOpen || isGPTChatOpen || isUsersOpen || isTranscriptOpen) 
                        ? 'calc(100% - 300px)' 
                        : '100%',
                }}
            >
                {mediaError ? (
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '100%',
                        padding: 2,
                        textAlign: 'center'
                    }}>
                        <Typography variant="h6" color="error" gutterBottom>
                            {mediaError}
                        </Typography>
                        <Button 
                            variant="contained" 
                            onClick={() => window.location.reload()}
                            sx={{ mt: 2 }}
                        >
                            Try Again
                        </Button>
                    </Box>
                ) : (
                    <>
                        <Box 
                            sx={{ 
                                flex: 1,
                                position: 'relative',
                                overflow: 'hidden'
                            }}
                        >
                            <VideoGrid 
                                streams={streamsWithUsernames}
                                participants={participants}
                                isHost={isHost}
                            />
                        </Box>
                        
                        <ExpandableControls
                            isMobile={isMobile}
                            controlsProps={{
                                isMuted,
                                isVideoOff,
                                onToggleAudio: handleToggleAudio,
                                onToggleVideo: handleToggleVideo,
                                onLeaveRoom: handleLeaveRoom,
                                onCopyRoomId: handleCopyRoomId,
                                onToggleChat: handleToggleChat,
                                isChatOpen,
                                disabled: isRetrying,
                                onToggleGPTChat: handleToggleGPTChat,
                                isGPTChatOpen,
                                onToggleUsers: handleToggleUsers,
                                isUsersOpen,
                                usersCount: participants.length,
                                webRTCService,
                                onToggleTranscript: handleToggleTranscript,
                                isTranscriptActive,
                                isHost,
                                stream: webRTCService.localStream,
                                onRecordingStateChange: handleRecordingStateChange
                            }}
                        />
                    </>
                )}

                <Snackbar
                    open={!!snackbarMessage}
                    autoHideDuration={6000}
                    onClose={() => setSnackbarMessage('')}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                >
                    <Alert 
                        onClose={() => setSnackbarMessage('')} 
                        severity={snackbarSeverity}
                        sx={{ width: '100%' }}
                    >
                        {snackbarMessage}
                    </Alert>
                </Snackbar>
            </Box>

            <Chat 
                isOpen={isChatOpen} 
                onClose={() => setIsChatOpen(false)} 
                roomId={roomId}
            />
            <GPTChat 
                isOpen={isGPTChatOpen} 
                onClose={() => setIsGPTChatOpen(false)} 
            />
            <UsersPanel 
                isOpen={isUsersOpen} 
                onClose={() => setIsUsersOpen(false)}
            />
            <TranscriptPanel
                isOpen={isTranscriptOpen}
                onClose={() => setIsTranscriptOpen(false)}
                transcript={transcript}
                isRecording={isTranscriptActive}
            />
        </Box>
    );
};

export default Room; 