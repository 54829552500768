import React, { useRef, useEffect, useState } from 'react';
import { Box, IconButton, Tooltip } from '@mui/material';
import Typography from '@mui/material/Typography';
import VideocamOffIcon from '@mui/icons-material/VideocamOff';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import VolumeIndicator from './VolumeIndicator';
import { useRoom } from '../../contexts/RoomContext';
import socketService from '../../services/socket';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import RecordingIndicator from '../RecordingIndicator/RecordingIndicator';
import webRTCService from '../../services/webRTC';

const VideoPlayer = ({ 
    stream, 
    username, 
    muted, 
    onExpand, 
    participantsCount = 0,
    isLocal
}) => {
    const videoRef = useRef(null);
    const containerRef = useRef(null);
    const [hasVideo, setHasVideo] = useState(false);
    const [videoError, setVideoError] = useState(false);
    const [isFullscreen, setIsFullscreen] = useState(false);
    const [showControls, setShowControls] = useState(false);
    const [isSpeaking, setIsSpeaking] = useState(false);
    const speakingTimeoutRef = useRef(null);
    const lastVolumesRef = useRef([]);
    const { roomIsRecording, showRecordingIndicator } = useRoom();

    useEffect(() => {
        console.log('VideoPlayer render:', {
            username,
            streamId: stream?.id,
            isLocal,
            roomIsRecording,
            showRecordingIndicator,
            shouldShowIndicator: roomIsRecording && showRecordingIndicator
        });
    }, [username, stream, isLocal, roomIsRecording, showRecordingIndicator]);

    useEffect(() => {
        if (!videoRef.current || !stream) return;

        const videoElement = videoRef.current;
        
        // Сначала останавливаем текущее воспроизведение
        if (videoElement.srcObject) {
            try {
                videoElement.pause();
            } catch (error) {
                console.warn('Error pausing video:', error);
            }
        }

        console.log('Setting new stream in VideoPlayer:', {
            username,
            streamId: stream.id,
            tracks: stream.getTracks().map(track => ({
                kind: track.kind,
                enabled: track.enabled,
                readyState: track.readyState
            }))
        });

        // Устанавливаем новый поток
        videoElement.srcObject = stream;
        setHasVideo(stream.getVideoTracks().length > 0);

        // Проверяем состояние видеотрека
        const videoTrack = stream.getVideoTracks()[0];
        if (videoTrack) {
            videoTrack.onended = () => console.log('Video track ended');
            videoTrack.onmute = () => console.log('Video track muted');
            videoTrack.onunmute = () => console.log('Video track unmuted');
        }

        // Используем Promise для воспроизведения
        const playVideo = async () => {
            try {
                await videoElement.play();
                console.log('Video playback started successfully');
            } catch (error) {
                console.error('Error playing video:', error);
                // Пробуем воспроизвести снова через небольшую задержку
                setTimeout(async () => {
                    try {
                        await videoElement.play();
                    } catch (retryError) {
                        console.error('Retry play failed:', retryError);
                    }
                }, 1000);
            }
        };

        playVideo();

        // Очистка при размонтировании
        return () => {
            if (videoElement.srcObject) {
                try {
                    videoElement.pause();
                    videoElement.srcObject = null;
                } catch (error) {
                    console.warn('Error cleaning up video element:', error);
                }
            }
        };
    }, [stream, username]);

    useEffect(() => {
        if (!stream || muted) return;

        const audioContext = new (window.AudioContext || window.webkitAudioContext)();
        const analyser = audioContext.createAnalyser();
        const source = audioContext.createMediaStreamSource(stream);
        source.connect(analyser);

        analyser.fftSize = 32;
        const dataArray = new Uint8Array(analyser.frequencyBinCount);
        let rafId;
        const volumeThreshold = 30;
        const requiredSamples = 50;
        const stableVolumeThreshold = 0.7;

        const checkVolume = () => {
            analyser.getByteFrequencyData(dataArray);
            const average = dataArray.reduce((a, b) => a + b) / dataArray.length;
            
            lastVolumesRef.current.push(average > volumeThreshold);
            if (lastVolumesRef.current.length > requiredSamples) {
                lastVolumesRef.current.shift();
            }

            const activeCount = lastVolumesRef.current.filter(Boolean).length;
            const isStablySpeaking = activeCount / lastVolumesRef.current.length >= stableVolumeThreshold;

            if (isStablySpeaking && !isSpeaking) {
                setIsSpeaking(true);
            } else if (!isStablySpeaking && isSpeaking) {
                if (speakingTimeoutRef.current) {
                    clearTimeout(speakingTimeoutRef.current);
                }
                speakingTimeoutRef.current = setTimeout(() => {
                    setIsSpeaking(false);
                }, 1000);
            }
            
            rafId = requestAnimationFrame(checkVolume);
        };

        checkVolume();

        return () => {
            cancelAnimationFrame(rafId);
            if (speakingTimeoutRef.current) {
                clearTimeout(speakingTimeoutRef.current);
            }
            source.disconnect();
            audioContext.close();
            lastVolumesRef.current = [];
        };
    }, [stream, muted, isSpeaking]);

    const handleFullscreenChange = () => {
        setIsFullscreen(document.fullscreenElement === containerRef.current);
    };

    useEffect(() => {
        document.addEventListener('fullscreenchange', handleFullscreenChange);
        return () => {
            document.removeEventListener('fullscreenchange', handleFullscreenChange);
        };
    }, []);

    const toggleFullscreen = async () => {
        try {
            if (!isFullscreen) {
                await containerRef.current.requestFullscreen();
            } else {
                await document.exitFullscreen();
            }
        } catch (error) {
            console.error('Error toggling fullscreen:', error);
        }
    };

    return (
        <Box
            ref={containerRef}
            sx={{
                position: 'relative',
                width: '100%',
                height: '100%',
                backgroundColor: 'black',
                borderRadius: 1,
                overflow: 'hidden',
                border: theme => participantsCount && participantsCount > 2 ? 
                    `2px solid ${isSpeaking ? theme.palette.primary.main : 'transparent'}` : 
                    'none',
                transition: 'border-color 0.2s ease-in-out',
                '&:hover .video-controls': {
                    opacity: 1
                }
            }}
            onMouseEnter={() => setShowControls(true)}
            onMouseLeave={() => setShowControls(false)}
        >
            <video
                ref={videoRef}
                autoPlay
                playsInline
                muted={muted}
                onError={() => setVideoError(true)}
                style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    colorProfile: 'srgb',
                    WebkitFilter: 'none',
                    filter: 'none'
                }}
            />

            {/* Индикатор записи */}
            {roomIsRecording && showRecordingIndicator && (
                <Box sx={{ 
                    position: 'absolute',
                    bottom: 8,
                    left: 8,
                    zIndex: 10
                }}>
                    <RecordingIndicator />
                </Box>
            )}

            {/* Username and volume indicator */}
            <Box
                sx={{
                    position: 'absolute',
                    top: 8,
                    left: 8,
                    display: 'flex',
                    alignItems: 'center',
                    backgroundColor: 'rgba(0, 0, 0, 0.6)',
                    padding: '4px 8px',
                    borderRadius: 1,
                    color: 'white',
                    fontSize: '0.875rem',
                    zIndex: 1
                }}
            >
                <Typography variant="body2">
                    {username}
                </Typography>
                {participantsCount && participantsCount > 2 && (
                    <VolumeIndicator 
                        stream={stream} 
                        isActive={!muted && isSpeaking}
                    />
                )}
            </Box>

            {/* Контролы видео */}
            <Box
                className="video-controls"
                sx={{
                    position: 'absolute',
                    bottom: 8,
                    right: 8,
                    display: 'flex',
                    gap: 1,
                    opacity: showControls ? 1 : 0,
                    transition: 'opacity 0.2s',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    padding: '4px',
                    borderRadius: 1,
                    zIndex: 1
                }}
            >
                <Tooltip title={isFullscreen ? "Exit fullscreen" : "Enter fullscreen"}>
                    <IconButton
                        size="small"
                        onClick={toggleFullscreen}
                        sx={{ color: 'white' }}
                    >
                        {isFullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
                    </IconButton>
                </Tooltip>

                {/* Показываем кнопку разворачивания только ��сли передан обработчик */}
                {onExpand && (
                    <Tooltip title="Expand view">
                        <IconButton
                            size="small"
                            onClick={onExpand}
                            sx={{ color: 'white' }}
                        >
                            <OpenInFullIcon />
                        </IconButton>
                    </Tooltip>
                )}
            </Box>

            {(!hasVideo || videoError) && (
                <Box
                    sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: 'rgba(0, 0, 0, 0.8)',
                        color: 'white',
                    }}
                >
                    <VideocamOffIcon sx={{ fontSize: 48, mb: 1 }} />
                    <Typography variant="body2">
                        {videoError ? 'Video unavailable' : 'Camera is off'}
                    </Typography>
                </Box>
            )}
        </Box>
    );
};

export default VideoPlayer; 