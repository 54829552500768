import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import VideoPlayer from '../VideoPlayer/VideoPlayer';
import RecordingIndicator from '../RecordingIndicator/RecordingIndicator';
import { useRoom } from '../../contexts/RoomContext';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

const VideoGrid = ({ streams, participants = [], isHost }) => {
    const { roomIsRecording, showRecordingIndicator } = useRoom();
    const [expandedStream, setExpandedStream] = useState(null);
    const [isMobile] = useState(() => /iPhone|iPad|iPod|Android/i.test(navigator.userAgent));

    useEffect(() => {
        console.log('VideoGrid state:', {
            isHost,
            isRecording: roomIsRecording,
            streams: streams.map(s => ({
                id: s.id,
                isLocal: s.isLocal,
                username: s.username
            }))
        });
    }, [isHost, roomIsRecording, streams]);

    const handleExpand = (stream) => {
        setExpandedStream(expandedStream === stream ? null : stream);
    };

    if (!streams || streams.length === 0) {
        return (
            <Box sx={{
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: 'text.secondary'
            }}>
                Waiting for video...
            </Box>
        );
    }

    // Специальная верстка для двух участников на мобильном
    if (streams.length === 2 && isMobile) {
        const localStream = streams.find(s => s.isLocal);
        const remoteStream = streams.find(s => !s.isLocal);

        return (
            <Box sx={{ 
                position: 'relative',
                width: '100%',
                height: '100dvh',
                backgroundColor: 'black',
                overflow: 'hidden'
            }}>
                {/* Удаленный поток на весь экран */}
                <Box sx={{ 
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0
                }}>
                    <VideoPlayer
                        stream={remoteStream.stream}
                        username={remoteStream.username}
                        muted={false}
                        participantsCount={participants.length}
                    />
                </Box>

                {/* Локальный поток в углу */}
                <Box sx={{ 
                    position: 'absolute',
                    top: 16,
                    right: 16,
                    width: '40%',
                    maxWidth: '200px',
                    minWidth: '120px',
                    aspectRatio: '16/9',
                    zIndex: 10,
                    borderRadius: 2,
                    overflow: 'hidden',
                    boxShadow: '0 4px 12px rgba(0,0,0,0.4)',
                    border: '2px solid rgba(255,255,255,0.2)',
                    cursor: 'move',
                    touchAction: 'none',
                    '&:active': {
                        cursor: 'grabbing'
                    }
                }}>
                    <VideoPlayer
                        stream={localStream.stream}
                        username={localStream.username}
                        muted={true}
                        participantsCount={participants.length}
                    />
                </Box>
            </Box>
        );
    }

    // Определяем стиль сетки в зависимости от количества участников и устройства
    const getGridStyle = (count) => {
        if (isMobile) {
            // Специальные стили для мобильных устройств
            switch (count) {
                case 1:
                    return {
                        gridTemplateColumns: '1fr',
                        gridAutoRows: '1fr'
                    };
                case 2:
                    return {
                        gridTemplateColumns: '1fr 1fr',
                        gridAutoRows: '1fr'
                    };
                case 3:
                case 4:
                    return {
                        gridTemplateColumns: '1fr 1fr',
                        gridAutoRows: '1fr'
                    };
                default: // 5 и более участников
                    return {
                        gridTemplateColumns: '1fr 1fr', // Всегда 2 колонки на мобильном
                        gridAutoRows: 'minmax(120px, 1fr)', // Минимальная высота дл видео
                        gap: 1 // Уменьшаем отступы между видео для мобильных
                    };
            }
        } else {
            // Стили для десктопа остаются прежними
            switch (count) {
                case 1:
                    return {
                        gridTemplateColumns: '1fr',
                        gridAutoRows: '1fr'
                    };
                case 2:
                    return {
                        gridTemplateColumns: '1fr 1fr',
                        gridAutoRows: '1fr'
                    };
                case 3:
                case 4:
                    return {
                        gridTemplateColumns: '1fr 1fr',
                        gridAutoRows: '1fr'
                    };
                case 5:
                case 6:
                    return {
                        gridTemplateColumns: '1fr 1fr 1fr',
                        gridAutoRows: '1fr'
                    };
                default:
                    return {
                        gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
                        gridAutoRows: '1fr'
                    };
            }
        }
    };

    const gridStyle = getGridStyle(streams.length);

    // Добавляем обработку расширенного вида
    if (expandedStream) {
        return (
            <Box sx={{ 
                position: 'relative',
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                paddingBottom: '20px'
            }}>
                {/* Развернутое видео с возможностью клика для возврата */}
                <Box 
                    sx={{ 
                        flex: 1,
                        position: 'relative',
                        marginBottom: '150px',
                        cursor: 'pointer',
                        '&:hover::after': {
                            content: '"Click to exit fullscreen"',
                            position: 'absolute',
                            top: '16px',
                            right: '16px',
                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                            color: 'white',
                            padding: '8px 12px',
                            borderRadius: '4px',
                            fontSize: '14px',
                            opacity: 0.8
                        }
                    }}
                    onClick={() => handleExpand(null)}
                >
                    <VideoPlayer
                        stream={expandedStream.stream}
                        username={expandedStream.username}
                        muted={expandedStream.isLocal}
                        onExpand={() => handleExpand(null)}
                        participantsCount={participants.length}
                    />
                </Box>

                {/* Полоса с остальными видео */}
                <Box sx={{
                    position: 'absolute',
                    bottom: '20px',
                    left: 0,
                    right: 0,
                    height: '150px',
                    display: 'flex',
                    gap: 1,
                    p: 1,
                    background: 'transparent',
                    backdropFilter: 'blur(8px)',
                    overflowX: 'auto',
                    boxShadow: '0px -2px 15px rgba(0, 0, 0, 0.2)',
                    zIndex: 1,
                    '&::-webkit-scrollbar': {
                        height: '6px',
                        background: 'transparent'
                    },
                    '&::-webkit-scrollbar-track': {
                        background: 'transparent',
                        margin: '0 4px'
                    },
                    '&::-webkit-scrollbar-thumb': {
                        background: 'rgba(255, 255, 255, 0.3)',
                        borderRadius: '3px',
                        '&:hover': {
                            background: 'rgba(255, 255, 255, 0.5)'
                        }
                    },
                    '&::before': {
                        content: '""',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        height: '20px',
                        background: 'linear-gradient(to bottom, rgba(0,0,0,0.2), transparent)',
                        pointerEvents: 'none'
                    }
                }}>
                    {streams
                        .filter(s => s !== expandedStream)
                        .map((stream) => (
                            <Box
                                key={stream.id}
                                sx={{
                                    width: '200px',
                                    height: '100%',
                                    flexShrink: 0,
                                    opacity: 0.85,
                                    transition: 'all 0.2s ease',
                                    transform: 'scale(0.98)',
                                    '&:hover': {
                                        opacity: 1,
                                        transform: 'scale(1)'
                                    }
                                }}
                            >
                                <VideoPlayer
                                    stream={stream.stream}
                                    username={stream.username}
                                    muted={stream.isLocal}
                                    onExpand={() => handleExpand(stream)}
                                    participantsCount={participants.length}
                                />
                            </Box>
                        ))}
                </Box>
            </Box>
        );
    }

    // Стандартная верстка для остальных случаев
    return (
        <Box sx={{ 
            position: 'relative', 
            width: '100%', 
            height: '100%'
        }}>
            {/* Сетка видео */}
            <Box 
                className="video-grid-container"
                sx={{
                    height: '100%',
                    width: '100%',
                    display: 'grid',
                    ...gridStyle,
                    gap: isMobile ? 1 : 2,
                    p: isMobile ? 1 : 2,
                    overflow: 'hidden',
                    position: 'relative'
                }}
            >
                {streams.map((stream) => (
                    <Box
                        key={stream.id}
                        sx={{
                            position: 'relative',
                            width: '100%',
                            height: '100%',
                            aspectRatio: '16/9',
                            backgroundColor: 'black',
                            borderRadius: 1,
                            overflow: 'hidden'
                        }}
                    >
                        <VideoPlayer
                            stream={stream.stream}
                            username={stream.username}
                            muted={stream.isLocal}
                            onExpand={() => handleExpand(stream)}
                            participantsCount={participants.length}
                            isLocal={stream.isLocal}
                        />
                    </Box>
                ))}
            </Box>
        </Box>
    );
};

export default VideoGrid; 