const environments = {
  production: {
    apiUrl: 'https://v.moneyshop.ua',
    socketUrl: window.location.origin,
    corsOrigins: [
      'https://v.moneyshop.ua',
      'wss://v.moneyshop.ua',
      'https://192.168.1.216:3000',
      'https://192.168.1.216:5000'
    ]
  },
  development: {
    apiUrl: `https://${window.location.hostname}:5000`,
    socketUrl: `https://${window.location.hostname}:5000`,
    corsOrigins: [
      'https://v.moneyshop.ua:3000',
      'https://v.moneyshop.ua:5000',
      'https://192.168.1.216:3000',
      'https://192.168.0.164:3000',
      'https://localhost:3000'
    ]
  },
  local: {
    apiUrl: 'https://localhost:5000',
    socketUrl: 'https://localhost:5000',
    corsOrigins: ['https://localhost:3000']
  }
};

export const getEnvironment = () => {
  // Определяем окружение
  if (window.location.hostname === 'v.moneyshop.ua') {
    return environments.production;
  }
  if (window.location.hostname === 'localhost') {
    return environments.local;
  }
  return environments.development;
}; 
