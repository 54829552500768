import { io } from 'socket.io-client';
import { getEnvironment } from '../environment';

const env = getEnvironment();
const SOCKET_SERVER = env.socketUrl;

class SocketService {
    constructor() {
        this.socket = null;
        this.roomId = null;
    }

    connect() {
        if (this.socket) return;

        this.socket = io(SOCKET_SERVER, {
            path: '/socket.io/',
            transports: ['websocket'],
            secure: true,
            rejectUnauthorized: false,
            reconnection: true,
            reconnectionAttempts: 5,
            reconnectionDelay: 1000,
            forceNew: true,
            timeout: 20000,
            withCredentials: true,
            extraHeaders: {
                "Access-Control-Allow-Origin": "*"
            }
        });

        // Добавляем общий обработчик всех событий для отладки
        this.socket.onAny((eventName, ...args) => {
            console.log('Socket event received:', {
                event: eventName,
                args,
                socketId: this.socket.id
            });
        });

        this.socket.on('connect', () => {
            console.log('Socket connected:', this.socket.id);
        });

        this.socket.on('disconnect', () => {
            console.log('Socket disconnected');
        });

        this.socket.on('connect_error', (error) => {
            console.error('Socket connection error:', error);
        });
    }

    setupSocketListeners() {
        this.socket.on('connect', () => {
            console.log('Connected to socket server with ID:', this.socket.id);
        });

        this.socket.on('disconnect', () => {
            console.log('Disconnected from socket server');
        });

        this.socket.on('error', (error) => {
            console.error('Socket error:', error);
        });
    }

    joinRoom(roomId, userData) {
        if (this.socket) {
            console.log('Joining room:', { roomId, userData });
            this.roomId = roomId;
            this.socket.userData = userData;
            this.socket.emit('join-room', roomId, userData);
        }
    }

    sendSignal(userId, signal) {
        console.log('Sending signal:', { to: userId, type: signal.type });
        this.socket.emit('signal', { userId, signal });
    }

    onUserJoined(callback) {
        if (this.socket) {
            this.socket.on('user-joined', callback);
        }
    }

    onUserLeft(callback) {
        this.socket.on('user-left', callback);
    }

    onRoomUsers(callback) {
        if (this.socket) {
            this.socket.on('room-users', callback);
        }
    }

    onSignal(callback) {
        this.socket.on('signal', callback);
    }

    disconnect() {
        if (this.socket) {
            console.log('Disconnecting socket...');
            
            // Удаляем все слушатели событий
            this.socket.removeAllListeners();
            
            // Отключаем сокет
            this.socket.disconnect();
            this.socket = null;
            
            console.log('Socket disconnected');
        }
    }

    onChatMessage(callback) {
        if (this.socket) {
            this.socket.on('chat-message', callback);
        }
    }

    sendChatMessage(message) {
        if (this.socket) {
            this.socket.emit('chat-message', message);
        }
    }

    sendDirectMessage(message) {
        if (!this.socket) {
            console.error('Socket not connected');
            return;
        }

        // Добавляем небольшую задержку перед отправкой
        setTimeout(() => {
            console.log('Sending direct message through socket:', message);
            this.socket.emit('direct-message', message);
        }, 0);
    }

    onDirectMessage(callback) {
        if (!this.socket) {
            console.error('Socket not connected');
            return;
        }

        // Удаляем предыдущий обработчик, если он есть
        this.socket.off('direct-message');
        
        // Устанавливаем новый обработчик
        this.socket.on('direct-message', (message) => {
            console.log('Socket received direct message:', message);
            callback(message);
        });
    }

    startTyping(chatType, recipientId = null, roomId = null) {
        if (!this.socket) return;
        
        const username = this.socket.userData?.username || 'Someone';
        
        const payload = {
            userId: this.socket.id,
            username: username,
            chatType,
            recipientId,
            roomId: roomId || this.socket.roomId
        };
        
        console.log('Sending typing-start:', payload);
        this.socket.emit('typing-start', payload);
    }

    stopTyping(chatType, recipientId = null, roomId = null) {
        if (!this.socket) return;
        
        const username = this.socket.userData?.username || 'Someone';
        
        const payload = {
            userId: this.socket.id,
            username: username,
            chatType,
            recipientId,
            roomId: roomId || this.socket.roomId
        };
        
        console.log('Sending typing-stop:', payload);
        this.socket.emit('typing-stop', payload);
    }

    onTypingStart(callback) {
        if (!this.socket) return;
        this.socket.on('typing-start', callback);
    }

    onTypingStop(callback) {
        if (!this.socket) return;
        this.socket.on('typing-stop', callback);
    }
}

const socketService = new SocketService();
export default socketService; 