import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import {
  Container,
  Box,
  Typography,
  Button,
  TextField,
  Paper,
  Stack
} from '@mui/material';

const Home = () => {
  const navigate = useNavigate();
  const [roomId, setRoomId] = useState('');
  const [username, setUsername] = useState('');

  const generateRandomUsername = () => {
    return `User${Math.random().toString(36).substring(2, 8)}`;
  };

  const createRoom = () => {
    const newRoomId = uuidv4();
    const finalUsername = username.trim() || generateRandomUsername();
    navigate(`/room/${newRoomId}?creator=true`, { state: { username: finalUsername } });
  };

  const joinRoom = () => {
    if (roomId.trim()) {
      const finalUsername = username.trim() || generateRandomUsername();
      navigate(`/room/${roomId.trim()}`, { state: { username: finalUsername } });
    }
  };

  return (
    <Container maxWidth="sm">
      <Box sx={{ 
        minHeight: '100dvh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        py: 3
      }}>
        <Paper elevation={3} sx={{ 
          p: 4, 
          width: '100%',
          maxWidth: '400px',
          mx: 2
        }}>
          <Stack spacing={3}>
            <Typography variant="h4" component="h1" align="center" gutterBottom>
              Video Chat
            </Typography>

            <TextField
              fullWidth
              label="Your Name"
              variant="outlined"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              placeholder="Enter your name (optional)"
              helperText="Leave empty for random name"
            />

            <TextField
              fullWidth
              label="Room ID"
              variant="outlined"
              value={roomId}
              onChange={(e) => setRoomId(e.target.value)}
              placeholder="Enter room ID to join"
            />

            <Button
              variant="contained"
              color="primary"
              size="large"
              fullWidth
              onClick={createRoom}
            >
              Create Room
            </Button>

            <Button
              variant="contained"
              color="secondary"
              size="large"
              fullWidth
              onClick={joinRoom}
              disabled={!roomId.trim()}
            >
              Join Room
            </Button>
          </Stack>
        </Paper>
      </Box>
    </Container>
  );
};

export default Home; 